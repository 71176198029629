import { useState } from "react";

import { CheckIcon, XMarkIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";

import { updateDoc, doc, writeBatch } from "firebase/firestore";
import { db } from "../firebase";

export const ThemeCard = ({ themes, theme, index }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(null);
  const [editName, setEditName] = useState(null);
  const [selectedThemeId, setSelectedThemeId] = useState(null);
  const [themeData, setThemeData] = useState(null);

  //Format date of card in dd/mm/yyyy form

  function formatDate(dateString) {
    if (!dateString) return ""; // Return empty string if dateString is not provided or invalid

    const date = dateString.toDate(); // Convert Firebase Timestamp to JavaScript Date object
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  //Updating themecards

  const handleEdit = (themeId) => {
    try {
        setIsEditing(true);
        setSelectedThemeId(themeId);
        const selectedTheme = themes.find((theme) => theme.id === themeId);
        setEditText(selectedTheme.text);
        setEditName(selectedTheme.name);
    } catch (error) {
        console.error("Error in handleEdit:", error);
    }
};

  const handleCancel = () => {
    setIsEditing(false);
    setSelectedThemeId(null);
    setEditText(null);
    setEditName(null);
  };

  // const handleNOK = async (themeId) => {
  //   const selectedTheme = themes.find((theme) => theme.id === themeId);

  //   if (selectedTheme) {
  //     const themeDocRef = doc(db, "themes", themeId);
  //     await updateDoc(themeDocRef, { status: "nok" });
  //     console.log("Theme status updated successfully.");
  //   }
  // };

  const handleNOK = async (themeId) => {
    try {
        const selectedTheme = themes.find((theme) => theme.id === themeId);
        if (selectedTheme) {
            const updated = themes.map((theme) => {
                if (theme.id === themeId) {
                    return {
                        ...theme,
                        status: "nok",
                    };
                }
                return theme;
            });
            setThemeData(updated);
            const themeDocRef = doc(db, "themes", themeId);
            await updateDoc(themeDocRef, { status: "nok" });
            console.log("Theme status updated successfully.");
        }
    } catch (error) {
        console.error("Error in handleNOK:", error);
    }
};

const handleOK = async (themeId) => {
  try {
      const selectedTheme = themes.find((theme) => theme.id === themeId);
      if (selectedTheme) {
          const updated = themes.map((theme) => {
              if (theme.id === themeId) {
                  return {
                      ...theme,
                      status: "ok",
                  };
              }
              return theme;
          });
          setThemeData(updated);
          const themeDocRef = doc(db, "themes", themeId);
          await updateDoc(themeDocRef, { status: "ok" });
          console.log("Theme status updated successfully.");
      }
  } catch (error) {
      console.error("Error in handleOK:", error);
  }
};

const handleSave = async () => {
  try {
      const updatedThemes = themes.map((theme) => {
          if (theme.id === selectedThemeId) {
              return {
                  ...theme,
                  text: editText || theme.text,
                  name: editName || theme.name,
              };
          }
          return theme;
      });

      const themeDocRef = doc(db, "themes", selectedThemeId);
      await updateDoc(themeDocRef, { edit_status: true });

      const batch = writeBatch(db);
      updatedThemes.forEach((theme) => {
          const themeDocRef = doc(db, "themes", theme.id);
          batch.update(themeDocRef, { text: theme.text, name: theme.name });
      });

      await batch.commit();
      console.log("Themes updated successfully.");

      setIsEditing(false);
      setSelectedThemeId(null);
      setEditText(null);
      setEditName(null);
  } catch (error) {
      console.error("Error in handleSave:", error);
  }
};

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div key={theme.id} className="bg-white rounded-lg shadow-sm mb-4">
      <div className="p-6">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-9 h-9 rounded-lg bg-gray-100 -ml-1 text-gray-600 text-xs font-semibold">
            {index + 1}
            {/* Incrementing number */}
          </div>
          <div className="ml-auto">
            <div className="ml-4 flex items-center gap-2">
              <button
                onClick={() => handleOK(theme.id)}
                type="button"
                className={classNames(
                  theme.status === "ok"
                    ? "text-success bg-success-100"
                    : "bg-gray-100",
                  "w-9 h-9 rounded-lg flex items-center justify-center"
                )}
              >
                <CheckIcon
                  className={classNames(
                    theme.status === "ok" ? "text-success" : "text-gray-500",
                    "h-5 w-5"
                  )}
                />
              </button>
              <button
                onClick={() => handleNOK(theme.id)}
                type="button"
                className={classNames(
                  theme.status === "nok"
                    ? "text-danger bg-danger-100"
                    : "bg-gray-100",
                  "w-9 h-9 rounded-lg flex items-center justify-center"
                )}
              >
                <XMarkIcon
                  className={classNames(
                    theme.status === "nok" ? "text-danger" : "text-gray-500",
                    "h-5 w-5"
                  )}
                />
              </button>
              <button
                type="button"
                className="w-9 h-9 rounded-lg bg-gray-100 flex items-center justify-center"
                onClick={() => handleEdit(theme.id)}
              >
                <PencilIcon className="h-5 w-5 text-gray-500" />
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex items-center mb-4">
            <h3 className="font-medium text-gray-900">{theme.name}</h3>
            {theme.status === "onhold" ? (
              <span className="rounded-full bg-gray-50 px-2.5 py-0.5 ml-2 text-[10px] uppercase text-white bg-gradient-to-r from-secondary to-tertiary">
                Nuevo
              </span>
            ) : null}
          </div>
          <p className="text-gray-500 text-sm">{theme.text}</p>
        </div>
      </div>
      <div className="border-t border-gray-200 px-6 py-2.5">
        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <div
              className={classNames(
                theme.status === "ok"
                  ? "text-success bg-success-100"
                  : theme.status === "nok"
                  ? "text-danger bg-danger-100"
                  : "text-warning bg-warning-100",
                "rounded flex items-center flex-nowrap h-5 px-2 text-xs font-medium"
              )}
            >
              <span
                className={classNames(
                  theme.status === "ok"
                    ? "bg-success-500"
                    : theme.status === "nok"
                    ? "bg-danger-500"
                    : "bg-warning-500",
                  "rounded-full w-1.5 h-1.5 mr-1.5 inline-block"
                )}
              />
              {theme.status === "ok"
                ? "Validado"
                : theme.status === "nok"
                ? "Descartado"
                : "Pendiente"}
            </div>
            {theme.edit_status && (
              <div className="rounded flex items-center flex-nowrap h-5 px-2 text-xs font-medium text-gray-500 bg-gray-100">
                <span className="rounded-full w-1.5 h-1.5 mr-1.5 inline-block bg-gray-400" />
                Editado
              </div>
            )}
          </div>
          <div className="flex items-center justify-end gap-2">
            <div className="text-xs border-r border-gray-200 pr-2">
              <span className="text-[10px] text-gray-500">Rango temporal:</span>{" "}
              {theme.time_range}
            </div>
            <div className="text-xs">
              <span className="text-[10px] text-gray-500">Creado:</span>{" "}
              {formatDate(theme.date_creation)}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        visible={isEditing && selectedThemeId === theme.id}
        onHide={handleCancel}
        header={
          <div className="flex items-center">
            <PencilIcon className="h-5 w-5 text-gray-500 mr-2" />
            Editar temática
          </div>
        }
        footer={
          <div className="flex justify-end">
            <Button
              label="Cancelar"
              className="p-button-outlined p-button-secondary"
              onClick={handleCancel}
            />
            <Button
              label="Guardar"
              className="p-button-secondary ml-2"
              onClick={handleSave}
            />
          </div>
        }
      >
        <form className="flex flex-col gap-6 text-sm">
          <div className="flex flex-col gap-2 text-sm">
            <label htmlFor="themeName">Temática:</label>
            <InputTextarea
              inputid="themeName"
              name="themeName"
              value={editName || theme.name}
              onChange={(e) => setEditName(e.target.value)}
              autoResize
              className="resize-none border border-gray-300 rounded px-2 py-1 w-full"
            />
          </div>
          <div className="flex flex-col gap-2 text-sm">
            <label htmlFor="description">Descripción de la Temática:</label>
            <InputTextarea
              inputid="description"
              name="description"
              value={editText || theme.text}
              onChange={(e) => setEditText(e.target.value)}
              autoResize
              className="resize-none border border-gray-300 rounded px-2 py-1 w-full"
            />
          </div>
        </form>
      </Dialog>
    </div>
  );
};
