/* eslint-disable no-undef */
import React from "react";
import { CheckIcon, XMarkIcon, PencilIcon } from "@heroicons/react/24/outline";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Layout } from "../layout/Layout";
import { Link } from "react-router-dom";
import { retrieveCategoryObjects } from "../services/DBService";
import { updateDatabaseCounts } from "../services/DBService";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import FilterCalendar from "../components/FilterCalendar";

export default function HomePage() {
  //consts

  const [themes, setThemes] = useState([]);
  const [spinners, setSpinners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [dateFilters, setDateFilters] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const CONCURRENT_LIMIT = 10; // For example, process 10 categories at a time
  const TIMEOUT_DURATION = 5000; // 5 seconds for each request
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {},
  });

  //fetch data

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve category objects array
        const categoryObjects = await retrieveCategoryObjects();
        setCategories(categoryObjects);
        setFilteredCategories(categoryObjects);
        // Set the initial filtered data to the categories
        setFilteredData(categoryObjects);
        // Execute updateDatabaseCounts
        await updateDatabaseCounts();
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();
  }, []);



  // Fetch data whenever lazyState changes

  const onSort = (event) => {
    const newLazyState = {
      ...lazyState,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
      first: 0, // Reset first to start from the first page
    };
    setLazyState(newLazyState);
  };

  const onFilter = async (event) => {
    try {
      // ... [rest of the code remains unchanged]

      const { startDate, endDate } = event.filters;
      if (startDate && endDate) {
        const filteredByDate = await handlerForFilterByDate(startDate.value, endDate.value);
        setFilteredData(filteredByDate);
      }

      // ... [rest of the code remains unchanged]
    } catch (error) {
      console.error("Error in onFilter:", error);
    }
  };





  //search bar

  const handleSearch = (event) =>
    setSearchQuery(event.target.value.toLowerCase());

  //change table page

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  //calculate stats cost

  const totalCost = filteredCategories.reduce(
    (acc, category) => acc + parseFloat(category.config.cost),
    0
  );
  const formattedCost = totalCost.toFixed(3).replace('.', ',');

  //stats

  const stats = [
    {
      id: 1,
      name: "Total temáticas",
      stat: filteredCategories.reduce(
        (count, category) => count + category.themes.gen,
        0
      ),
      check: filteredCategories.reduce(
        (count, category) => count + category.themes.ok,
        0
      ),
      cross: filteredCategories.reduce(
        (count, category) => count + category.themes.nok,
        0
      ),
      pencil: filteredCategories.reduce(
        (count, category) => count + category.themes.edited,
        0
      ),
    },
    {
      id: 2,
      name: "Total spinners",
      stat: filteredCategories.reduce(
        (count, category) => count + category.spinners.gen,
        0
      ),
      check: filteredCategories.reduce(
        (count, category) => count + category.spinners.ok,
        0
      ),
      cross: filteredCategories.reduce(
        (count, category) => count + category.spinners.nok,
        0
      ),
      pencil: filteredCategories.reduce(
        (count, category) => count + category.spinners.edited,
        0
      ),
    },
    {
      id: 3,
      name: "Coste total",
      stat: formattedCost,
    },
  ];

  //for calendar

  // async function handlerForFilterByDate(startDate, endDate, toFilterData) {
  //   try {
  //     let filteredData = await Promise.all(
  //       toFilterData.map(async (category) => {
  //         const spinnersCollection = collection(db, "spinners");
  //         const themesCollection = collection(db, "themes");

  //         const spinnersQuery = query(
  //           spinnersCollection,
  //           where("idCategory", "==", category.id),
  //           where("date_creation", ">=", startDate),
  //           where("date_creation", "<=", endDate)
  //         );
  //         const themesQuery = query(
  //           themesCollection,
  //           where("idCategory", "==", category.id),
  //           where("date_creation", ">=", startDate),
  //           where("date_creation", "<=", endDate)
  //         );

  //         const [spinnersSnapshot, themesSnapshot] = await Promise.all([
  //           getDocs(spinnersQuery),
  //           getDocs(themesQuery),
  //         ]);

  //         const spinnersData = spinnersSnapshot.docs.map((doc) => doc.data());
  //         const themesData = themesSnapshot.docs.map((doc) => doc.data());

  //         const spinners = {
  //           gen: spinnersData.length,
  //           ok: spinnersData.filter((spinner) => spinner.status === "ok").length,
  //           nok: spinnersData.filter((spinner) => spinner.status === "nok")
  //             .length,
  //           edited: spinnersData.filter((spinner) => spinner.edit_status === true)
  //             .length,
  //           onhold: spinnersData.filter((spinner) => spinner.status === "onhold")
  //             .length,
  //         };

  //         const themes = {
  //           gen: themesData.length,
  //           ok: themesData.filter((theme) => theme.status === "ok").length,
  //           nok: themesData.filter((theme) => theme.status === "nok").length,
  //           edited: themesData.filter((theme) => theme.edit_status === true)
  //             .length,
  //           onhold: themesData.filter((theme) => theme.status === "onhold")
  //             .length,
  //         };

  //         const config = {
  //           cost: (
  //             spinnersData.reduce(
  //               (sum, spinner) => sum + spinner.config.cost,
  //               0
  //             ) + themesData.reduce((sum, theme) => sum + theme.config.cost, 0)
  //           ).toFixed(2),
  //         };

  //         // Check if spinners and themes have at least one number greater than 0
  //         const hasPositiveNumber =
  //           Object.values(spinners).some((value) => value > 0) ||
  //           Object.values(themes).some((value) => value > 0);

  //         // Return null if all values are 0, indicating that the object should be removed
  //         if (!hasPositiveNumber) {
  //           return null;
  //         }

  //         return {
  //           ...category,
  //           spinners,
  //           themes,
  //           config,
  //         };
  //       })
  //     );

  //     // Remove null values (objects with all 0s) from the array
  //     filteredData = filteredData.filter((items) => items !== null);

  //     return filteredData;
  //   } catch (error) {
  //     console.error("Error in handlerForFilterByDate:", error);
  //     return []; // Or handle it in another manner
  //   }
  // }

  function chunkArray(array, size) {
    const chunked = [];
    let index = 0;
    while (index < array.length) {
        chunked.push(array.slice(index, size + index));
        index += size;
    }
    return chunked;
}

function timeout(ms, promise) {
  return new Promise((resolve, reject) => {
      setTimeout(() => {
          reject(new Error('Timeout'));
      }, ms);
      promise.then(resolve, reject);
  });
}

async function handlerForFilterByDate(startDate, endDate, toFilterData) {
  try {
      const batches = chunkArray(toFilterData, CONCURRENT_LIMIT);
      let allFilteredData = [];

      for (const batch of batches) {
          const batchResults = await Promise.allSettled(batch.map(category => {
              return timeout(TIMEOUT_DURATION, processCategory(category, startDate, endDate));
          }));

          // Extract successful results and log errors
          for (const result of batchResults) {
              if (result.status === 'fulfilled') {
                  allFilteredData.push(result.value);
              } else {
                  console.error("Error processing category:", result.reason);
              }
          }
      }

      // Remove null values (objects with all 0s) from the array
      const filteredData = allFilteredData.filter(item => item !== null);

      return filteredData;
  } catch (error) {
      console.error("Unexpected error in handlerForFilterByDate:", error);
      return []; // Or handle it in another manner
  }
}


async function processCategory(category, startDate, endDate) {
  try {
      const spinnersCollection = collection(db, "spinners");
      const themesCollection = collection(db, "themes");

      const spinnersQuery = query(
          spinnersCollection,
          where("idCategory", "==", category.id),
          where("date_creation", ">=", startDate),
          where("date_creation", "<=", endDate)
      );
      const themesQuery = query(
          themesCollection,
          where("idCategory", "==", category.id),
          where("date_creation", ">=", startDate),
          where("date_creation", "<=", endDate)
      );

      const [spinnersSnapshot, themesSnapshot] = await Promise.all([
          getDocs(spinnersQuery),
          getDocs(themesQuery),
      ]);

      const spinnersData = spinnersSnapshot.docs.map((doc) => doc.data());
      const themesData = themesSnapshot.docs.map((doc) => doc.data());

      const spinners = {
          gen: spinnersData.length,
          ok: spinnersData.filter((spinner) => spinner.status === "ok").length,
          nok: spinnersData.filter((spinner) => spinner.status === "nok").length,
          edited: spinnersData.filter((spinner) => spinner.edit_status === true).length,
          onhold: spinnersData.filter((spinner) => spinner.status === "onhold").length,
      };

      const themes = {
          gen: themesData.length,
          ok: themesData.filter((theme) => theme.status === "ok").length,
          nok: themesData.filter((theme) => theme.status === "nok").length,
          edited: themesData.filter((theme) => theme.edit_status === true).length,
          onhold: themesData.filter((theme) => theme.status === "onhold").length,
      };

      const config = {
          cost: (
              spinnersData.reduce((sum, spinner) => sum + spinner.config.cost, 0) +
              themesData.reduce((sum, theme) => sum + theme.config.cost, 0)
          ).toFixed(2),
      };

      // Check if spinners and themes have at least one number greater than 0
      const hasPositiveNumber = 
          Object.values(spinners).some((value) => value > 0) ||
          Object.values(themes).some((value) => value > 0);

      // Return null if all values are 0, indicating that the object should be removed
      if (!hasPositiveNumber) {
          return null;
      }

      return {
          ...category,
          spinners,
          themes,
          config,
      };
  } catch (error) {
      console.error("Error processing category:", category, error);
      return null; // Or handle it in another manner
  }
}



  return (
    <>
      <Layout>
        <div className="mx-auto xl:max-w-7xl px-4 sm:px-6 lg:px-8 py-8 md:py-12 text-center">
          <h1 className="text-3xl lg:text-4xl xl:text-5xl font-semibold">
            Revoluciona la
            <span className="block text-secondary">creación de contenidos</span>
          </h1>
          <p className="text-lg mt-2">
            IA para la creación de contenido dinámico
          </p>
          <form className="flex flex-col gap-2 md:flex-row mt-6">
            <div className="p-input-icon-left grow">
              <i className="pi pi-search " />
              <InputText
                className="w-full"
                placeholder="Buscar actividad o servicio"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <div>
              <FilterCalendar
                initialData={categories}
                setFilterInitialData={setFilteredCategories}
                searchQuery={searchQuery}
                handlerForFilterByDate={handlerForFilterByDate}
                setSearchQuery={setSearchQuery}
                showClearFilterButton
              />
            </div>
          </form>
          {/* KPIs */}
          <div className="mt-6">
            <dl className="grid grid-cols-1 gap-2 lg:grid-cols-3">
              {stats.map((item) => (
                <div
                  key={item.id}
                  className="relative overflow-hidden rounded-lg bg-white p-6 shadow-sm text-left"
                >
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd>
                    <p className="text-3xl font-semibold text-gray-900">
                      {item.stat}
                      {item.name === "Coste total" ? (
                        <span className="text-xl">€</span>
                      ) : null}
                    </p>
                    {item.name === "Coste total" ? null : (
                      <>
                        <div className="absolute inset-y-0 right-0 py-3 px-3 xl:px-5 bg-gray-10 flex flex-col justify-center items-center border-l border-gray-200 text-gray-500">
                          <div className="flex inline-block mb-2">
                            <CheckIcon className="h-4 w-4" />
                            <span className="ml-2 w-11 h-5 bg-white rounded-xl flex items-center justify-center text-xs">
                              {item.check}
                            </span>
                          </div>
                          <div className="flex inline-block mb-2">
                            <XMarkIcon className="h-4 w-4" />
                            <span className="ml-2 w-11 h-5 bg-white rounded-xl flex items-center justify-center text-xs">
                              {item.cross}
                            </span>
                          </div>
                          <div className="flex inline-block">
                            <PencilIcon className="h-4 w-4" />
                            <span className="ml-2 w-11 h-5 bg-white rounded-xl flex items-center justify-center text-xs">
                              {item.pencil}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          {/* End KPIs */}
          {/* Table */}
          <div className="mt-6">
            <DataTable

              value={filteredCategories}
              dataKey="id"
              paginator
              first={first}
              rows={rows}
              totalRecords={filteredCategories.length}
              onPage={onPageChange}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              onFilter={onFilter}
              filters={lazyState.filters}
              removableSort
            >

              {/* <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column> */}
              <Column
                sortable
                sortField="name"
                field="name"
                header="Actividad"
                body={(rowData) => (
                  <Link to={`/${rowData.id}/overview`}>{rowData.name}</Link>
                )}
              />
              <Column
                field="themes.gen"
                header="Temáticas:"
                sortable
                sortField="themes.gen"
                body={() => <span style={{ display: 'none' }}></span>}
              />
              <Column
                //field="themes.gen" 
                header={() => <SparklesIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.themes.gen}
                  </span>
                )}
              />
              <Column
                // field="themes.ok"
                header={() => <CheckIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.themes.ok}
                  </span>
                )}
              />
              <Column
                // field="themes.edited"
                header={() => <PencilIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.themes.edited}
                  </span>
                )}
              />
              <Column
                // field="themes.nok"
                header={() => <XMarkIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.themes.nok}
                  </span>
                )}
              />
              <Column field="" header="Spinners:" />
              <Column
                sortable
                field="spinners.gen"
                header={() => <SparklesIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.spinners.gen}
                  </span>
                )}
              />
              <Column
                // field="spinners.ok"
                header={() => <CheckIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.spinners.ok}
                  </span>
                )}
              />
              <Column
                // field="spinners.edited"
                header={() => <PencilIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.spinners.edited}
                  </span>
                )}
              />
              <Column
                // field="spinners.nok"
                header={() => <XMarkIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.spinners.nok}
                  </span>
                )}
              />
              <Column
                // field="config.cost"
                header="Coste"
                body={(rowData) => <span>{rowData.config?.cost}€</span>}
              />
            </DataTable>
            <div className="flex mt-2">
            </div>
          </div>
          {/* End Table */}
        </div>
        // eslint-disable-next-line no-undef
        return <button onClick={() => methodDoesNotExist()}>Break the world</button>;
      </Layout>
    </>
  );
}
