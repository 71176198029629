import React, { useState, useEffect } from "react";
import {
  CheckIcon,
  XMarkIcon,
  PencilIcon,
  ArrowRightIcon,
  ArrowDownTrayIcon,
  FolderIcon,
  Squares2X2Icon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";

import { SparklesIcon } from "@heroicons/react/24/solid";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { Layout } from "../layout/Layout";
import Sidebar from "../layout/Sidebar";
import { useParams } from "react-router-dom";
import { query, where } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import { updateDatabaseCounts } from "../services/DBService";
import FilterCalendar from "../components/FilterCalendar";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export default function CategoryPage() {
  //consts

  const [themes, setThemes] = useState([]);
  const [filteredThemes, setFilteredThemes] = useState([]);
  const [spinners, setSpinners] = useState([]);
  const { idCategory } = useParams();
  const [selectedThemes, setSelectedThemes] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const navigation = [
    {
      name: "Overview",
      href: "/overview",
      icon: Squares2X2Icon,
      current: true,
    },
    { name: "Temáticas (eventos)", href: "/themes", icon: FolderIcon, current: false },
    {
      name: "Spinners (plantillas)",
      href: "/overview/spinners",
      icon: RectangleStackIcon,
      current: false,
    },
  ];

  const [catName, setCatName] = useState(null);

  const fetchCategoryName = async (idCategory) => {
    try {
      const categoriesColRef = collection(db, "categories");
      const querySnapshot = await getDocs(
        query(categoriesColRef, where("id", "==", parseInt(idCategory)))
      );

      if (querySnapshot.empty) {
        // Handle case where the category with the given id does not exist
        console.log("Category not found");
        return;
      }

      querySnapshot.forEach((doc) => {
        const categoryName = doc.data().name;
        setCatName(categoryName);
      });
    } catch (error) {
      // Handle any errors that occur during the fetching process
      console.error("Error fetching category:", error);
    }
  };

  console.log(catName);

  useEffect(() => {
    fetchCategoryName(idCategory);
  }, [idCategory]);

  //fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const themesArray = await updateDatabaseCounts();
        const themes = themesArray.filter(
          (theme) => theme.idCategory === parseInt(idCategory)
        );
        console.log("Themes array fetched successfully:", themes);
        console.log(idCategory);
        setThemes(themes);
        setFilteredThemes(themes);
        // Use the filteredThemes in your code as needed
      } catch (error) {
        console.error("Error fetching themes array:", error);
      }
    };

    fetchData(); // Call the fetch function inside the useEffect

    // Add any dependencies to the dependency array if needed
  }, [idCategory]);

  // const fetchThemesArray = () => {
  //   updateDatabaseCounts()
  //     .then((themesArray) => {
  //       console.log("Themes array fetched successfully:", themesArray);
  //       // Use the themesArray in your code as needed
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching themes array:", error);
  //     });
  // };

  // fetchThemesArray();

  const fetchSpinners = async (categoryId) => {
    try {
      const spinnersCollection = collection(db, "spinners");
      onSnapshot(
        query(
          spinnersCollection,
          where("idCategory", "==", parseInt(categoryId))
        ),
        (snapshot) => {
          const spinnersData = snapshot.docs.map((doc) => doc.data());
          setSpinners(spinnersData);
        }
      );
    } catch (error) {
      console.error("Error fetching spinners:", error);
    }
  };

  useEffect(() => {
    fetchSpinners(parseInt(idCategory));
  }, [idCategory]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            // Retrieve category objects array
            await updateDatabaseCounts();
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, []);

  //fomat date in table

  function formatDate(date) {
    if (!date) return ""; // Return empty string if date is not provided or invalid

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());
    return `${day}/${month}/${year}`;
  }

  //stats

  const stats = [
    {
      id: 1,
      name: "Total temáticas",
      stat: filteredThemes.length,
      link: `/${idCategory}/themes`,
      check: filteredThemes.filter((theme) => theme.status === "ok").length,
      cross: filteredThemes.filter((theme) => theme.status === "nok").length,
      pencil: filteredThemes.filter((theme) => theme.edit_status === true)
        .length,
    },
    {
      id: 2,
      name: "Total spinners",
      stat: spinners.filter((spinner) => {
        return (
          spinner.idCategory === parseInt(idCategory) &&
          filteredThemes.some((theme) => theme.id === spinner.idTheme)
        );
      }).length,
      link: `/${idCategory}/overview/spinners`,
      check: spinners.filter((spinner) => {
        return (
          spinner.idCategory === parseInt(idCategory) &&
          filteredThemes.some(
            (theme) => theme.id === spinner.idTheme && spinner.status === "ok"
          )
        );
      }).length,
      cross: spinners.filter((spinner) => {
        return (
          spinner.idCategory === parseInt(idCategory) &&
          filteredThemes.some(
            (theme) => theme.id === spinner.idTheme && spinner.status === "nok"
          )
        );
      }).length,
      pencil: spinners.filter((spinner) => {
        return (
          spinner.idCategory === parseInt(idCategory) &&
          filteredThemes.some(
            (theme) =>
              theme.id === spinner.idTheme && spinner.edit_status === true
          )
        );
      }).length,
    },
    {
      id: 3,
      name: "Coste total",
      stat: filteredThemes
        .filter((theme) => theme.idCategory === parseInt(idCategory))
        .reduce((acc, theme) => acc + parseFloat(theme.config.total_cost), 0)
        .toFixed(3).replace('.', ','),
    },
  ];

  //search bar

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  //for calendar

  // async function handlerForFilterByDate(startDate, endDate, toFilterData) {
  //   const themesCollection = collection(db, "themes");
  //   const themesQuery = query(
  //     themesCollection,
  //     where("idCategory", "==", parseInt(idCategory)), // Filter by idCategory
  //     where("date_creation", ">=", startDate),
  //     where("date_creation", "<=", endDate)
  //   );

  //   const themesSnapshot = await getDocs(themesQuery);
  //   const dateFilteredData = themesSnapshot.docs.map((doc) => doc.data());

  //   // Apply name filter on the date filtered data
  //   const nameFilteredData = dateFilteredData.filter((theme) =>
  //     theme.name.toLowerCase().includes(searchQuery)
  //   );

  //   return nameFilteredData;
  // }

  //GET ALL THE ULFILTERED DATA HERE (SEE ABOVE EXAMPLE)

  async function handlerForFilterByDate(startDate, endDate, toFilterData) {
    try {
      const themesArray = await updateDatabaseCounts();
      const themes = themesArray.filter(
        (theme) => theme.idCategory === parseInt(idCategory)
      );
      setThemes(themes);
      const dateFilteredData = themes.filter(
        (theme) =>
          theme.date_creation >= startDate && theme.date_creation <= endDate
      );
      const nameFilteredData = dateFilteredData.filter((theme) =>
        theme.name.toLowerCase().includes(searchQuery)
      );
      return nameFilteredData;
    } catch (error) {
      console.error("Error filtering themes array:", error);
      throw error;
    }
  }

  //excel download
  const exportToExcel = async () => {
    try {
      // Retrieve the categories from Firebase
      const categoriesSnapshot = await getDocs(collection(db, "categories"));
      const categories = categoriesSnapshot.docs.reduce((acc, doc) => {
        const category = doc.data();
        acc[category.id] = category.name;
        return acc;
      }, {});

      // Retrieve the spinner text from Firebase
      const spinnersQuery = query(collection(db, 'spinners'), where('status', '==', 'ok'));
      const spinnersSnapshot = await getDocs(spinnersQuery);
      const spinners = spinnersSnapshot.docs.reduce((acc, doc) => {
        const spinner = doc.data();
        const themeId = spinner.idTheme;
        if (!acc[themeId]) {
          acc[themeId] = [];
        }
        acc[themeId].push(spinner.text);
        return acc;
      }, {});

      // Prepare the worksheet data
      let worksheetData;

      if (selectedThemes && selectedThemes.length > 0) {
        worksheetData = selectedThemes.flatMap((rowData) => {
          if (rowData.status !== "ok") {
            return []; // Skip themes that are not "ok"
          }

          const spinnerTexts = spinners[rowData.id] || [];
          
          // Create an array of objects for each spinner text with corresponding category and theme names
          return spinnerTexts.map((text) => ({
            Categoría: categories[rowData.idCategory] || "",
            "Nombre de temática": rowData.name,
            "Spinner Text": text,
          }));
        });
      } else {
        worksheetData = filteredThemes.flatMap((rowData) => {
          if (rowData.status !== "ok") {
            return []; // Skip themes that are not "ok"
          }

          const spinnerTexts = spinners[rowData.id] || [];

          // Create an array of objects for each spinner text with corresponding category and theme names
          return spinnerTexts.map((text) => ({
            Categoría: categories[rowData.idCategory] || "",
            "Nombre de temática": rowData.name,
            "Spinner Text": text,
          }));
        });
      }

      // Prepare the worksheet
      const worksheet = XLSX.utils.json_to_sheet(worksheetData);

      // Prepare the workbook
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };

      // Convert the workbook to an Excel file buffer
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob object from the buffer
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Save the Blob object as an Excel file
      saveAs(blob, "table_data.xlsx");
    } catch (error) {
      // Handle the error appropriately
      console.error("Error exporting data:", error);
    }
  };

  //change table page

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const [selectAll, setSelectAll] = useState(false);

  const onSelectionChange = (event) => {
    const value = event.value;
    setSelectedThemes(value);
    setSelectAll(value.length === filteredThemes.length);
  };

  const onSelectAllChange = (event) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectedThemes(filteredThemes);
    } else {
      setSelectedThemes([]);
    }

    setSelectAll(selectAll);
  };

  return (
    <Layout>
      {/* Sidebar */}
      <Sidebar navigation={navigation} />
      {/* End Sidebar */}
      <div className="pl-20 xl:pl-64 min-h-[--main-height]">
        <div className="mx-auto xl:max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex items-center">
            <h1 className="text-3xl flex items-center">Overview</h1>
            <div className="ml-auto">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-dark px-3 py-2 text-sm text-white"
                onClick={exportToExcel}
              >
                <ArrowDownTrayIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                Descargar
              </button>
            </div>
          </div>
          <h2 className="block xl:hidden text-xl font-semibold truncate">
            {catName}
          </h2>
          <form className="flex flex-col gap-2 md:flex-row mt-6">
            <div className="p-input-icon-left grow">
              <i className="pi pi-search " />
              <InputText
                className="w-full"
                placeholder="Buscar temática"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <div>
              <FilterCalendar
                initialData={themes}
                setFilterInitialData={setFilteredThemes}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handlerForFilterByDate={handlerForFilterByDate}
                showClearFilterButton
              />
            </div>
          </form>
          {/* KPIs */}
          <div className="mt-6">
            <dl className="grid grid-cols-1 gap-2 lg:grid-cols-3">
              {stats.map((item) => (
                <div
                  key={item.id}
                  className="relative overflow-hidden rounded-lg bg-white p-6 shadow-sm text-left"
                >
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd>
                    <p className="text-3xl font-semibold text-gray-900">
                      {item.stat}
                      {item.name === "Coste total" ? (
                        <span className="text-xl">€</span>
                      ) : null}
                    </p>
                    {item.name === "Coste total" ? null : (
                      <>
                        <div className="absolute inset-y-0 right-0 py-3 px-3 xl:px-5 bg-gray-10 flex flex-col justify-center items-center border-l border-gray-200 text-gray-500">
                          <div className="flex inline-block mb-2">
                            <CheckIcon className="h-4 w-4" />
                            <span className="ml-2 w-11 h-5 bg-white rounded-xl flex items-center justify-center text-xs">
                              {item.check}
                            </span>
                          </div>
                          <div className="flex inline-block mb-2">
                            <XMarkIcon className="h-4 w-4" />
                            <span className="ml-2 w-11 h-5 bg-white rounded-xl flex items-center justify-center text-xs">
                              {item.cross}
                            </span>
                          </div>
                          <div className="flex inline-block">
                            <PencilIcon className="h-4 w-4" />
                            <p className="ml-2 w-11 h-5 bg-white rounded-xl flex items-center justify-center text-xs">
                              {item.pencil}
                            </p>
                          </div>
                        </div>
                        <div className="mt-2 leading-none">
                          {item.link ? (
                            <Link
                              to={item.link}
                              className="text-xs font-medium text-secondary hover:text-secondary"
                            >
                              Ver todos
                            </Link>
                          ) : (
                            <span className="text-xs font-medium">
                              {item.name} stats
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          {/* End KPIs */}
          {/* Table */}
          <div className="mt-6">
            <DataTable
              value={filteredThemes
                .filter((theme) => theme.status === "ok")
                .slice(first, first + rows)}
              tableStyle={{ minWidth: "50rem" }}
              selectionMode="checkbox"
              selection={selectedThemes}
              onSelectionChange={(e) => setSelectedThemes(e.value)}
              dataKey="id"
              selectAll={selectAll}
              onSelectAllChange={onSelectAllChange}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>
              <Column field="name" header="Temáticas validadas" />
              <Column
                field="date_creation"
                header="Creación"
                body={(rowData) => formatDate(rowData.date_creation)}
              />
              <Column
                // field="spinners.gen"
                header={() => <SparklesIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.spinners.gen}
                  </span>
                )}
              />
              <Column
                // field="spinners.ok"
                header={() => <CheckIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.spinners.ok}
                  </span>
                )}
              />
              <Column
                // field="spinners.edited"
                header={() => <PencilIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.spinners.edited}
                  </span>
                )}
              />
              <Column
                // field="spinners.nok"
                header={() => <XMarkIcon className="h-4 w-4" />}
                body={(rowData) => (
                  <span className="bg-gray-100 text-xs rounded-xl py-1 px-2.5">
                    {rowData.spinners.nok}
                  </span>
                )}
              />
              <Column
                // field="config.total_cost"
                header="Coste"
                body={(rowData) => (
                  <span>
                    {parseFloat(rowData.config.total_cost).toFixed(3)}€
                  </span>
                )}
              />
              <Column
                headerStyle={{ width: "1px" }}
                body={(rowData) => (
                  <Link
                    className="flex items-center flex-nowrap text-secondary text-xs whitespace-nowrap"
                    to={`/${rowData.idCategory}/overview/${rowData.id}/spinner`}
                  >
                    Ver spinners <ArrowRightIcon className="h-3 w-3 ml-2" />
                  </Link>
                )}
              />
            </DataTable>
            <div className="flex mt-2">
              <Paginator
                className="ml-auto"
                first={first}
                rows={rows}
                totalRecords={filteredThemes.length}
                rowsPerPageOptions={[5, 10, 20, 30]}
                onPageChange={onPageChange}
              />
            </div>
          </div>
          {/* End Table */}
        </div>
      </div>
    </Layout>
  );
}
