import React, { createContext, useState, useEffect } from "react";

import { auth } from "./firebase";

import { signOut } from "@firebase/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [initialAuthCheckDone, setInitialAuthCheckDone] = useState(false);
  const [isVerificationSuccessful, setIsVerificationSuccessful] = useState(() => {
    try {
        const storedValue = localStorage.getItem('isVerificationSuccessful');
        const timestamp = localStorage.getItem('loginTimestamp');
        
        const currentTime = new Date().getTime();
        const timeLimit = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
        
        if (timestamp && (currentTime - timestamp) > timeLimit) {
            localStorage.removeItem('isVerificationSuccessful');
            localStorage.removeItem('loginTimestamp');
            return false;
        }

        return storedValue ? JSON.parse(storedValue) : false;
    } catch (error) {
        console.error("Error reading isVerificationSuccessful from localStorage:", error);
        return false;
    }
});

 

  function logoutUserReactState() {
    setUser(null);
  }

  useEffect(() => {
    try {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        setUser(user);
        console.log("User state changed:", user);
        setLoading(false);
        setInitialAuthCheckDone(true);
      });
  
      return unsubscribe;
    } catch (error) {
      console.error("Error during onAuthStateChanged:", error);
    }
  }, []);

// Also, when the isVerificationSuccessful state changes:
useEffect(() => {
    console.log("isVerificationSuccessful changed:", isVerificationSuccessful);
    localStorage.setItem('isVerificationSuccessful', JSON.stringify(isVerificationSuccessful));
}, [isVerificationSuccessful]);

  useEffect(() => {
    console.log("isVerificationSuccessful changed:", isVerificationSuccessful);
    localStorage.setItem('isVerificationSuccessful', JSON.stringify(isVerificationSuccessful));
    
    if(isVerificationSuccessful) {
        const timestamp = new Date().getTime();
        localStorage.setItem('loginTimestamp', timestamp);
    } else {
        localStorage.removeItem('loginTimestamp');
    }
}, [isVerificationSuccessful]);

const handleLogoutGen = async () => {

  console.log("executing handleLogoutGen")
  
  try {
    await signOut(auth);
    localStorage.removeItem('email');
    localStorage.removeItem('isVerificationSuccessful');
    setIsVerificationSuccessful(false);
    logoutUserReactState();
  } catch (error) {
    console.log("Error during logout:", error);
  }
};

  if (loading) {
    return (
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    ); // O simplemente return null si no quieres mostrar nada
  }




  return (
    <AuthContext.Provider
      value={{
        user,
        logoutUserReactState,
        isVerificationSuccessful, // Make the state available in the context
        setIsVerificationSuccessful,
        isSubmitClicked,
        setIsSubmitClicked, // Make the setter available in the context
        handleLogoutGen
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
