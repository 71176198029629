import { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordReset = async () => {
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage(
        "Revisa tu Bandeja de entrada correo de recuperación de contraseña enviado correctamente"
      );
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(
        "Error al enviar el correo de recuperación de contraseña: " +
          error.message
      );
      setSuccessMessage("");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full p-6 bg-white rounded shadow">
        <h1 className="text-2xl font-bold mb-6">
          Ingresa tu email y te diremos qué pasos seguir
        </h1>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700">Email:</label>
            <input
              className="w-full border border-gray-300 px-3 py-2 rounded focus:outline-none focus:border-blue-500"
              type="email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <button
            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-300"
            type="button"
            onClick={handlePasswordReset}
          >
            Enviar solicitud
          </button>
          <Link
            className="underline mx-auto w-full text-blue-700 "
            to={"/login"}
          >
            Volver al login
          </Link>
        </form>
        {successMessage && (
          <p className="text-green-600 mt-4">{successMessage}</p>
        )}
        {errorMessage && <p className="text-red-600 mt-4">{errorMessage}</p>}
      </div>
    </div>
  );
}
