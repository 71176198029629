import {
  collection,
  onSnapshot,
  query,
  where,
  updateDoc,
  doc,
  writeBatch,
  getDocs,
  Timestamp
} from "firebase/firestore";
import { db } from "../firebase";

//THREE QUERIES INSTEAD OF ONE FOR EACH CATEGORY

//RETRIEVE ALL THE THEMES/SPINNERS/CATEGORIES FIRST

//THEN CREATE THE ARRAY HERE



export const retrieveCategoryObjects = async () => {
  try {
    const categoriesData = [];

    // Fetch categories collection
    const categoriesCollection = collection(db, "categories");
    const categoriesQuery = query(categoriesCollection);
    const categoriesSnapshot = await getDocs(categoriesQuery);

    // Fetch spinners and themes data
    const spinnersCollection = collection(db, "spinners");
    const spinnersSnapshot = await getDocs(spinnersCollection);
    const spinnersData = spinnersSnapshot.docs.map((doc) => doc.data());

    const themesCollection = collection(db, "themes");
    const themesSnapshot = await getDocs(themesCollection);
    const themesData = themesSnapshot.docs.map((doc) => doc.data());

    const categoryPromises = categoriesSnapshot.docs.map(async (categoryDoc) => {
      const categoryData = categoryDoc.data();
      const categoryId = categoryData.id;

      // Filter spinners with the same idCategory
      const spinnersFiltered = spinnersData.filter(
        (spinnerData) => spinnerData.idCategory === categoryId
      );

      // Filter themes with the same idCategory
      const themesFiltered = themesData.filter(
        (themeData) => themeData.idCategory === categoryId
      );

      // Count spinners with different statuses
      let spinnersCount = 0;
      let okCount = 0;
      let nokCount = 0;
      let onholdCount = 0;
      let editedCount = 0;
      let cost = 0;

      spinnersFiltered.forEach((spinnerData) => {
        const status = spinnerData.status;
        const editStatus = spinnerData.edit_status;
        //const spinnerCost = spinnerData.config.cost;

        spinnersCount++;
        // cost += spinnerCost;

        if (status === "ok") {
          okCount++;
        } else if (status === "nok") {
          nokCount++;
        } else if (status === "onhold") {
          onholdCount++;
        }

        if (editStatus === true) {
          editedCount++;
        }
      });

      // Count themes with different statuses
      let themesCount = 0;
      let categoryOkCount = 0;
      let categoryNokCount = 0;
      let categoryOnholdCount = 0;
      let categoryEditedCount = 0;

      themesFiltered.forEach((themeData) => {
        const status = themeData.status;
        const editStatus = themeData.edit_status;
        const themeCost = themeData.config.total_cost;
        const parsedThemeCost = parseFloat(themeCost);

        themesCount++;
        cost += parsedThemeCost;

        if (status === "ok") {
          categoryOkCount++;
        } else if (status === "nok") {
          categoryNokCount++;
        } else if (status === "onhold") {
          categoryOnholdCount++;
        }

        if (editStatus === true) {
          categoryEditedCount++;
        }
      });

      // Create category object with data
      const categoryObject = {
        id: categoryId,
        name: categoryData.name,
        spinners: {
          gen: spinnersCount,
          ok: okCount,
          nok: nokCount,
          onhold: onholdCount,
          edited: editedCount,
        },
        themes: {
          gen: themesCount,
          ok: categoryOkCount,
          nok: categoryNokCount,
          onhold: categoryOnholdCount,
          edited: categoryEditedCount,
        },
        config: {
          cost: cost.toFixed(3),
        },
      };

      return categoryObject;
    });

    const categoryObjects = await Promise.all(categoryPromises);

    categoriesData.push(...categoryObjects);

    // Log the categories data
    console.log(categoriesData);

    return categoriesData;
  } catch (error) {
    console.error("Error retrieving category objects:", error);
    return [];
  }
};




export const updateDatabaseCounts = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const themesCollection = collection(db, "themes");
      const themesQuery = query(themesCollection);
      const themesSnapshot = await getDocs(themesQuery);

      const themesArray = []; // Array to store theme objects

      const spinnersCollection = collection(db, "spinners");
      const spinnersSnapshot = await getDocs(spinnersCollection);

      const spinnersByTheme = {}; // Object to store spinners by theme ID

      spinnersSnapshot.forEach((spinnerDoc) => {
        const spinnerData = spinnerDoc.data();
        const idTheme = spinnerData.idTheme;

        if (!spinnersByTheme[idTheme]) {
          spinnersByTheme[idTheme] = [];
        }

        spinnersByTheme[idTheme].push(spinnerData);
      });

      themesSnapshot.forEach((themeDoc) => {
        const themeData = themeDoc.data();
        const id = themeData.id;
        const status = themeData.status;

 
       

        const spinnersData = spinnersByTheme[id] || [];

        let spinnersCount = 0;
        let okCount = 0;
        let nokCount = 0;
        let onholdCount = 0;
        let editedCount = 0;

        spinnersData.forEach((spinnerData) => {
          const status = spinnerData.status;
          const editStatus = spinnerData.edit_status;

          spinnersCount++;

          if (status === "ok") {
            okCount++;
          } else if (status === "nok") {
            nokCount++;
          } else if (status === "onhold") {
            onholdCount++;
          }

          if (editStatus === true) {
            editedCount++;
          }
        });

        const themeConfig = themeData.config || {};

        const themeObject = {
          config: {
            cost: themeConfig.cost,
            model: themeConfig.model,
            prompt_tokens: themeConfig.prompt_tokens,
            respond_tokens: themeConfig.respond_tokens,
            total_cost: themeConfig.total_cost,
            total_tokens: themeConfig.total_tokens,
          },
 date_creation: themeData.date_creation.toDate(), 
          status: themeData.status,
          edit_status: themeData.edit_status,
          id: themeData.id,
          idCategory: themeData.idCategory,
          name: themeData.name,
          text: themeData.text,
          time_range: themeData.time_range,
          spinners: {
            gen: spinnersCount,
            ok: okCount,
            nok: nokCount,
            onhold: onholdCount,
            edited: editedCount,
          },
        };

        themesArray.push(themeObject);

        console.log(themeObject)
      });

      console.log("Themes array created successfully:", themesArray);
      resolve(themesArray);
    } catch (error) {
      console.error("Error creating themes array:", error);
      reject(error);
    }
  });
};



export default updateDatabaseCounts;
