import logo from "./logo.svg";
import "./App.css";
import { useRef, useState, useEffect } from "react";
import LoginPage from "./pages/LoginPage";
import CategoryPage from "./pages/CategoryPage";
import HomePage from "./pages/HomePage";
import ThemePage from "./pages/ThemePage";
import SpinnerPage from "./pages/SpinnerPage.js";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import { useContext } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ForgotPassword from "./pages/ForgotPassword";
import { signOut } from "@firebase/auth";



function PrivateLayout() {
  const auth = useContext(AuthContext);
  const isVerificationSuccessful = auth.isVerificationSuccessful;

  console.log("PrivateLayout - user:", auth.user);
  console.log("PrivateLayout - isVerificationSuccessful:", isVerificationSuccessful);

  // if (!auth.initialAuthCheckDone) {
  //   return null; // or a loading spinner
  // }
 
  if (auth.user && isVerificationSuccessful) {
    return <Outlet />;
  } else { 
    console.log("returned to login")
    return <Navigate to="/login" replace />;
  }
}


function PublicLayout() {
  const auth = useContext(AuthContext);
  const isVerificationSuccessful = auth.isVerificationSuccessful;

  console.log("PublicLayout - user:", auth.user);
  console.log("PublicLayout - isVerificationSuccessful:", isVerificationSuccessful);

  // if (!auth.initialAuthCheckDone) {
  //   return null; // or a loading spinner
  // }

  if (auth.user && isVerificationSuccessful) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}

//checks if user is active and updates timestamp for log in

const useActivityTracker = () => { 
  
  const { setIsVerificationSuccessful, handleLogoutGen } = useContext(AuthContext);
  
  useEffect(() => {
  
    const updateTimestamp = () => {
      const timestamp = new Date().getTime();
      localStorage.setItem('loginTimestamp', timestamp);
    };

    const checkInactivity = () => {
      const timestamp = localStorage.getItem('loginTimestamp');
      const currentTime = new Date().getTime();
     const timeLimit = 1 * 60 * 60 * 1000; // 1 hour in milliseconds


      if (timestamp && (currentTime - timestamp) > timeLimit) {
        console.log("log in time exceeded")
        setIsVerificationSuccessful(false);
        handleLogoutGen();  // This will handle the logout process and remove necessary items from localStorage
      }
    };

    window.addEventListener('mousemove', updateTimestamp);
    window.addEventListener('keydown', updateTimestamp);
    window.addEventListener('scroll', updateTimestamp);
    window.addEventListener('click', updateTimestamp);
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        checkInactivity();
      }
    });

    return () => {
      window.removeEventListener('mousemove', updateTimestamp);
      window.removeEventListener('keydown', updateTimestamp);
      window.removeEventListener('scroll', updateTimestamp);
      window.removeEventListener('click', updateTimestamp);
      document.removeEventListener('visibilitychange', checkInactivity);
    };
  }, [handleLogoutGen, setIsVerificationSuccessful]);
};




export default function AppRoutes() {


  
  useActivityTracker(); 

  return (
    <Router>
      <Routes>
        <Route path="/" element={<PrivateLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/:idCategory/themes" element={<ThemePage />} />
          <Route path="/:idCategory/overview" element={<CategoryPage />} />
          <Route
            path="/:idCategory/overview/:idTheme/spinner"
            element={<SpinnerPage />}
          />
          <Route
            path="/:idCategory/overview/spinners"
            element={<SpinnerPage />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
        <Route path="/" element={<PublicLayout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password" element={<ForgotPassword />} />
        </Route>
      </Routes>
    </Router>
  );
}
