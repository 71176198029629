import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";// Import the Firebase database configuration
import { useState, useEffect } from "react";
import { collection, query, where, getDocs } from 'firebase/firestore';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Sidebar = ({ navigation }) => {

  const { idCategory} = useParams();
  const [catName, setCatName] = useState(null);

  const fetchCategoryName = async (idCategory) => {
    try {
      const categoriesColRef = collection(db, 'categories');
      const querySnapshot = await getDocs(query(categoriesColRef, where('id', '==', parseInt(idCategory))));
  
      if (querySnapshot.empty) {
        // Handle case where the category with the given id does not exist
        console.log('Category not found');
        return;
      }
  
      querySnapshot.forEach((doc) => {
        const categoryName = doc.data().name;
        setCatName(categoryName);
      });
    } catch (error) {
      // Handle any errors that occur during the fetching process
      console.error('Error fetching category:', error);
    }
  };
 
  fetchCategoryName(idCategory)
  
  
  
  

  return (
    <>
      <div className="fixed inset-y-0 w-20 xl:w-64 flex flex-col">
        <div className="flex grow flex-col overflow-y-auto bg-gray-10 px-5 py-8">
          <h2 className='text-xl leading-tight font-bold hidden xl:block'>{catName}</h2>
          <nav className="mt-[72px] xl:mt-9">
            <ul className="flex flex-1 flex-col gap-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={`/${idCategory}${item.href}/`}
                    className={classNames(
                      item.current
                        ? 'flex items-center justify-center xl:justify-start bg-secondary-50 text-secondary'
                        : 'flex items-center justify-center xl:justify-start hover:bg-gray-100',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-secondary' : '',
                        'h-5 w-5 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    <span className='ml-1 hidden xl:block'>{item.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

