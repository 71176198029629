import React from "react";
import {
  XMarkIcon,
  FolderIcon,
  Squares2X2Icon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
import { Layout } from "../layout/Layout";
import {
  collection,
  query,
  where,
  doc,
  onSnapshot,
  getDoc,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";
import updateDatabaseCounts from "../services/DBService";
import Sidebar from "../layout/Sidebar";
import API from "../utils/api";
import Spinner from "../components/Spinner";
import FilterCalendar from "../components/FilterCalendar";
import SpinnerCard from "../components/SpinnerCard";

export default function SpinnerPage() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [spinners, setSpinners] = useState([]);
  const [filterSpinnerData, setFilterSpinnerData] = useState([]);
  const [themes, setThemes] = useState([]);
  const [compSpinner, setCompSpinner] = useState([]);
  const [promptSpinner, setPromptSpinner] = useState([]);
  const [totalSpinner, setTotalSpinner] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const { idCategory, idTheme } = useParams();
  const [searchQuery, setSearchQuery] = useState(null);
  const [numSpinners, setNumSpinners] = useState(1);
  const [selectedTheme, setSelectedTheme] = useState();
  const [startDateSpinner, setStartDateSpinner] = useState(null);
  const [endDateSpinner, setSetendDateSpinner] = useState(null);
  const [catName, setCatName] = useState("");
  const navigate = useNavigate();

  const navigation = [
    {
      name: "Overview",
      href: "/overview",
      icon: Squares2X2Icon,
      current: false,
    },
    { name: "Temáticas (eventos)", href: "/themes", icon: FolderIcon, current: false },
    {
      name: "Spinners (plantillas)",
      href: "/overview/spinners",
      icon: RectangleStackIcon,
      current: true,
    },
  ];

  //updateDatabaseCounts();

  const fetchCategoryName = async (idCategory) => {
    try {
      const categoriesColRef = collection(db, "categories");
      const querySnapshot = await getDocs(
        query(categoriesColRef, where("id", "==", parseInt(idCategory)))
      );

      if (querySnapshot.empty) {
        // Handle case where the category with the given id does not exist
        console.log("Category not found");
        return;
      }

      querySnapshot.forEach((doc) => {
        const categoryName = doc.data().name;
        setCatName(categoryName);
      });
    } catch (error) {
      // Handle any errors that occur during the fetching process
      console.error("Error fetching category:", error);
    }
  };

  useEffect(() => {
    fetchCategoryName(idCategory);
  }, [idCategory]);

  const fetchSpinners = async (idCategory, idTheme) => {
    try {
      const spinnersCollection = collection(db, "spinners");
      let queryRef;
      if (idTheme) {
        // Fetch spinners based on idTheme
        console.log("It's idTheme time")
        queryRef = query(
          spinnersCollection, 
          where("idTheme", "==", idTheme),
          orderBy("date_creation", "desc")
        );
        
      } else {
        // Fetch spinners based on idCategory
        queryRef = query(
          spinnersCollection,
          where("idCategory", "==", parseInt(idCategory)),
          orderBy("date_creation", "desc")
        );
      }

      onSnapshot(queryRef, (snapshot) => {
        const updatedSpinnersData = snapshot.docs.map((doc) => doc.data());
        updatedSpinnersData.sort((a, b) => {
          // Compare and sort based on your criteria
          // For example, sorting by date_creation in descending order
          return b.date_creation - a.date_creation;
        });

        // Reverse the array if needed
        // This will put the newest spinner at the top when idTheme exists
       

        let filteredHelperData = updatedSpinnersData;

        if (startDateSpinner && endDateSpinner) {
          filteredHelperData = filterSpinnersByDateHelper(
            startDateSpinner,
            endDateSpinner,
            updatedSpinnersData
          );
        }

        setSpinners(updatedSpinnersData);
        setFilterSpinnerData(filteredHelperData);

        // Calculate compSpinner here using updatedSpinnersData
        if (idTheme) {
          const compSpinnerValue = updatedSpinnersData
            .filter((spinner) => spinner.idTheme === idTheme)
            .reduce(
              (sum, spinner) => sum + (spinner.config?.respond_tokens || 0),
              0
            );
          setCompSpinner(compSpinnerValue);

          const promptSpinnerValue = updatedSpinnersData
            .filter((spinner) => spinner.idTheme === idTheme)
            .reduce(
              (sum, spinner) => sum + (spinner.config?.prompt_tokens || 0),
              0
            );
          setPromptSpinner(promptSpinnerValue);

          const totalSpinnerValue = updatedSpinnersData
            .filter((spinner) => spinner.idTheme === idTheme)
            .reduce(
              (sum, spinner) => sum + (spinner.config?.total_tokens || 0),
              0
            );
          setTotalSpinner(totalSpinnerValue);
        } else {
          const compSpinnerValue = updatedSpinnersData
            .filter((spinner) => spinner.idCategory === idCategory)
            .reduce(
              (sum, spinner) => sum + (spinner.config?.respond_tokens || 0),
              0
            );
          setCompSpinner(compSpinnerValue);

          const promptSpinnerValue = updatedSpinnersData
            .filter((spinner) => spinner.idCategory === idCategory)
            .reduce(
              (sum, spinner) => sum + (spinner.config?.prompt_tokens || 0),
              0
            );
          setPromptSpinner(promptSpinnerValue);

          const totalSpinnerValue = updatedSpinnersData
            .filter((spinner) => spinner.idCategory === idCategory)
            .reduce(
              (sum, spinner) => sum + (spinner.config?.total_tokens || 0),
              0
            );
          setTotalSpinner(totalSpinnerValue);
        }
      });
    } catch (error) {
      console.error("Error fetching spinners:", error);
    }
  };

  const fetchThemes = async (categoryId) => {
    try {
      const themesCollection = collection(db, "themes");
      onSnapshot(
        query(
          themesCollection,
          where("idCategory", "==", parseInt(categoryId))
        ),
        (snapshot) => {
          const themesData = snapshot.docs.map((doc) => doc.data());
          setThemes(themesData);
        }
      );
    } catch (error) {
      console.error("Error fetching themes:", error);
    }
  };

  const fetchTheme = async (themeId) => {
    try {
      const themeDoc = doc(db, "themes", themeId);
      const themeSnapshot = await getDoc(themeDoc);
      if (themeSnapshot.exists()) {
        const themeData = themeSnapshot.data();
        // Hacer algo con el tema obtenido...
        console.log("Tema encontrado:", themeData);
        setSelectedTheme(themeData);
      } else {
        console.log("No se encontró el tema con el ID especificado.");
      }
    } catch (error) {
      console.error("Error al obtener el tema:", error);
    }
  };

  useEffect(() => {
    fetchThemes(idCategory);
    if (idTheme) {
      fetchSpinners(undefined, idTheme);
      fetchTheme(idTheme);
    } else {
      fetchSpinners(Number(idCategory));
    }
  }, [idCategory, idTheme, endDateSpinner, startDateSpinner]);


  useEffect(() => {
    const fetchData = async () => {
        try {
            // Execute updateDatabaseCounts
            await updateDatabaseCounts();
        } catch (error) {
            console.error("Error in fetchData:", error);
        }
    };

    fetchData();
}, []);


  const handleButtonClick = () => {
    setShowForm(true);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const tabs = [
    {
      name: "Generados",
      href: "#",
      count: filterSpinnerData.length,
      current: true,
      value: "gen",
    },
    {
      name: "Validados",
      href: "#",
      count: filterSpinnerData.filter((spinner) => spinner.status === "ok")
        .length,
      current: false,
      value: "ok",
    },
    {
      name: "Editados",
      href: "#",
      count: filterSpinnerData.filter((spinner) => spinner.edit_status === true)
        .length,
      current: false,
      value: "edit",
    },
    {
      name: "Descartados",
      href: "#",
      count: filterSpinnerData.filter((spinner) => spinner.status === "nok")
        .length,
      current: false,
      value: "nok",
    },
    {
      name: "Pendientes",
      href: "#",
      count: filterSpinnerData.filter((spinner) => spinner.status === "onhold")
        .length,
      current: false,
      value: "onhold",
    },
  ];

  const [activeTab, setActiveTab] = useState(
    tabs.find((tab) => tab.current).name
  );

  function handleTabClick(tabName) {
    setActiveTab(tabName);
  }

  const [tabValue, setTabValue] = useState(
    tabs.find((tab) => tab.current).value
  );

  useEffect(() => {
    setTabValue(tabs.find((tab) => tab.name === activeTab).value);
  }, [activeTab]);

  /*  const generateCards2 = () => {
    let filteredSpinners = filterSpinnerData;

    if (activeTab === "Generados") {
      filteredSpinners = spinners;
    } else if (activeTab === "Validados") {
      filteredSpinners = spinners.filter((spinner) => spinner.status === "ok");
    } else if (activeTab === "Descartados") {
      filteredSpinners = spinners.filter((spinner) => spinner.status === "nok");
    } else if (activeTab === "Editados") {
      filteredSpinners = spinners.filter((spinner) => spinner.edit_status);
    } else if (activeTab === "Pendientes") {
      filteredSpinners = spinners.filter(
        (spinner) => spinner.status === "onhold"
      );
    }

    const spinnerCards = filteredSpinners.map((spinner, index) =>
      generateCard(spinner, index)
    );

    return {
      spinnerCards,
      filteredSpinners,
    };
  }; */

  // const { spinnerCards, filteredSpinners } = generateCards2();

  const formattedSpinners = filterSpinnerData.map((spinner) => ({
    id: spinner.id,
    idTheme: spinner.idTheme,
    config: spinner.config,
    idCategory: spinner.idCategory,
    status: spinner.status,
    date_creation: spinner.date_creation,
    prompt_tokens: spinner.config?.prompt_tokens || 0,
    total_tokens: spinner.config?.total_tokens || 0,
    cost: spinner.cost || 0,
  }));

  const stats = [
    {
      id: 1,
      name: "Completion tokens",
      stat: formattedSpinners
        .reduce(
          (sum, spinner) => sum + (spinner.config?.respond_tokens || 0),
          0
        )
        .toFixed(0),
    },
    {
      id: 2,
      name: "Prompt tokens",
      stat: formattedSpinners
        .reduce((sum, spinner) => sum + (spinner.config?.prompt_tokens || 0), 0)
        .toFixed(0),
    },
    {
      id: 3,
      name: "Total tokens",
      stat: formattedSpinners
        .reduce((sum, spinner) => sum + (spinner.config?.total_tokens || 0), 0)
        .toFixed(0),
    },
    {
      id: 4,
      name: "Coste total",
      stat: formattedSpinners
        .reduce((acc, spinner) => acc + (spinner.config.cost || 0), 0)
        .toFixed(3).replace('.', ','),
    },
  ];

  const [selectedThemeOption, setSelectedThemeOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const postSpinner = async (themeId) => {
    const payload = {
      idCategory: Number(idCategory),
      themeId,
      numSpinners: Number(numSpinners)
    };
    setIsLoading(true);

    console.log("postSpinner idcat", idCategory)
    
    console.log("postSpinner themeid", themeId)

    
    console.log("postSpinner numspin", numSpinners)

    console.log("Error numSpin", payload.numSpinners)
    console.log("Error idTheme", payload.themeId)
    console.log("Error idCat", payload.idCategory)

    try {
      setIsLoading(true);
      const response = await fetch(
        `${API.ROOT}${API.ADD_SPINNER}/${payload.idCategory}/${payload.themeId}/${payload.numSpinners}`
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("POST SPINNER request successful:", responseData);
        setIsLoading(false);
        navigate(`/${idCategory}/overview/${idTheme}/spinner`);
      } else {
        console.log("Error numSpin", payload.numSpinners)
        console.log("Error idTheme", payload.themeId)
        console.log("Error idCat", payload.idCategory)
        console.log("POST SPINNER request failed:", response.status);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error making POST SPINNER request:", error);
      setIsLoading(false);
    }
  };

  

  function handleThemeOption(e) {
    setSelectedThemeOption(e.target.value);
  }

async function handleSubmitCreateSpinners(e) {
    e.preventDefault();
    try {
        if (idTheme && selectedTheme) {
            console.log("idTheme:", idTheme);
            await postSpinner(idTheme);
            setShowForm(false);
        } else {
            if (!selectedThemeOption) return alert("Selecciona una temática");
            console.log("selectedThemeOption:", selectedThemeOption);
            await postSpinner(selectedThemeOption);
            setShowForm(false);
        }
    } catch (error) {
        console.error("Error in handleSubmitCreateSpinners:", error);
    }
}


  function getSpinnerByDate(startDate, endDate) {
    const spinnersCollection = collection(db, "spinners");
    let q;
    if (idTheme) {
      q = query(
        spinnersCollection,
        where("idTheme", "==", idTheme),
        where("date_creation", ">=", startDate),
        where("date_creation", "<=", endDate)
      );
    } else {
      q = query(
        spinnersCollection,
        where("date_creation", ">=", startDate),
        where("date_creation", "<=", endDate)
      );
    }

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const spinnerData = snapshot.docs.map((doc) => doc.data());
          setFilterSpinnerData(spinnerData);
          unsubscribe(); // Dejar de escuchar cambios después de obtener los datos
          resolve(spinnerData);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async function handleFilterSpinnerByDate(startDate, endDate, toFilterData) {
    try {
        setStartDateSpinner(startDate);
        setSetendDateSpinner(endDate);
        const spinnerData = await getSpinnerByDate(startDate, endDate);

        return spinnerData;
    } catch (error) {
        console.error("Error in handleFilterSpinnerByDate:", error);
        return []; // Or handle it in another manner
    }
}


  function filterSpinnersByDateHelper(startDate, endDate, spinnersData) {
    return spinnersData.filter((theme) => {
      const themeDate = new Date(theme.date_creation.seconds * 1000);
      return themeDate >= startDate && themeDate <= endDate;
    });
  }
  
  const handleNumSpinnersChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setNumSpinners(newValue);
  };

  return (
    <Layout>
      {/* Sidebar */}
      <Sidebar navigation={navigation} />
      {/* End Sidebar */}
      <div className="pl-20 xl:pl-64 min-h-[--main-height]">
        <div className="mx-auto xl:max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex items-center">
            <h1 className="text-3xl truncate grow">Spinners</h1>

            <div className="ml-auto">
              <button
                onClick={() => handleButtonClick()}
                type="button"
                className="inline-flex items-center rounded-md px-3 py-2 text-sm text-white whitespace-nowrap bg-gradient-to-r from-secondary to-tertiary"
              >
                <SparklesIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                Generar spinner
              </button>
            </div>
          </div>
          <h2 className="block xl:hidden text-xl font-semibold truncate">
            {catName}
          </h2>
          <p className="text-secondary font-semibold">{`${
            idTheme ? selectedTheme?.name : ""
          }`}</p>
          {/* Filtros */}
          <div className="flex flex-col gap-2 md:flex-row mt-6">
            <FilterCalendar
              initialData={spinners}
              setFilterInitialData={setFilterSpinnerData}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              showClearFilterButton
              handlerForFilterByDate={handleFilterSpinnerByDate}
            />
          </div>
          {/* End Filtros */}
          {/* KPIs */}
          <div className="mt-6">
            <dl className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
              {stats.map((item) => (
                <div
                  key={item.id}
                  className="relative overflow-hidden rounded-lg bg-white p-6 shadow-sm text-left"
                >
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd>
                    <p className="text-3xl font-semibold text-gray-900">
                      {item.stat}
                      {item.name === "Coste total" ? (
                        <span className="text-xl">€</span>
                      ) : null}
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          {/* End KPIs */}
          {/* Tabs */}
          <div className="mt-6 sm:mt-4">
            {/* Tabs mobile */}
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={activeTab}
                onChange={(e) => handleTabClick(e.target.value)}
              >
                {tabs.map((tab) => (
                  <option key={tab.name} value={tab.name}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            {/* Tabs desktop */}
            <div className="hidden sm:block">
              <div className="border-y border-gray-200">
                <nav
                  className="-mb-px flex space-x-8 overflow-x-auto overflow-y-hidden"
                  aria-label="Tabs"
                >
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      className={classNames(
                        activeTab === tab.name
                          ? "border-primary text-primary"
                          : "border-transparent text-gray-500 hover:text-gray-700",
                        "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                      )}
                      onClick={() => handleTabClick(tab.name)}
                      aria-current={activeTab === tab.name ? "page" : undefined}
                    >
                      {tab.name}
                      {/* {tab.count ? ( */}
                      <span
                        className={classNames(
                          activeTab === tab.name
                            ? "bg-primary-500 text-primary"
                            : "bg-white text-gray-900",
                          "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block"
                        )}
                      >
                        {tab.count}
                      </span>
                      {/* ) : null} */}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          {/* End Tabs */}

          {/* Listado de Spinners */}
          {filterSpinnerData.length > 0 ? (
            <div className="mt-6">
              {" "}
              <div className="mt-6">
                {tabValue === "gen" &&
                  filterSpinnerData.map((spinner, index) => (
                    <SpinnerCard
                      spinner={spinner}
                      setSpinners={setSpinners}
                      index={index}
                      themes={themes}
                      setData={setFilterSpinnerData}
                      data={filterSpinnerData}
                      idCategory={idCategory}
                    />
                  ))}

                {tabValue !== "gen" &&
                  tabValue !== "edit" &&
                  filterSpinnerData.map(
                    (spinner, index) =>
                      spinner.status === tabValue && (
                        <SpinnerCard
                          spinner={spinner}
                          index={index}
                          themes={themes}
                          setData={setFilterSpinnerData}
                          data={spinners}
                          idCategory={idCategory}
                        />
                      )
                  )}

                {tabValue === "edit" &&
                  filterSpinnerData.map(
                    (spinner, index) =>
                      spinner.edit_status && (
                        <SpinnerCard
                          spinner={spinner}
                          index={index}
                          themes={themes}
                          setData={setFilterSpinnerData}
                          data={spinners}
                          idCategory={idCategory}
                        />
                      )
                  )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center mt-10">
              <div className="text-4xl">
                <code>(&gt;_&lt;)</code>
              </div>
              <p className="block text-sm mt-6">No hay spinners para mostrar</p>
              <p className="block text-gray-400 text-sm">
                Crea tu primer spinner
              </p>
              <button
                onClick={() => handleButtonClick()}
                type="button"
                className="mt-6 inline-flex items-center rounded-md px-3 py-2 text-sm text-white bg-gradient-to-r from-secondary to-tertiary"
              >
                <SparklesIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                Generar spinner
              </button>
            </div>
          )}
          {/* End Listado de Spinners */}

          {showForm && (
            <form
              className="fixed z-10 inset-0 overflow-y-auto"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
              onSubmit={handleSubmitCreateSpinners}
            >
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                  aria-hidden="true"
                ></div>
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <div
                  className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-title"
                >
                  <div className="py-5 px-6 flex border border-gray-100">
                    <div className="flex items-center">
                      <SparklesIcon className="h-4 w-4 text-secondary mr-2" />
                      <h5 className="font-medium" id="modal-title">
                        Crear Spinners
                      </h5>
                    </div>
                    <button
                      type="button"
                      className="h-8 w-8 flex items-center justify-center text-gray-500 ml-auto rounded-full overflow-hidden hover:bg-gray-100 hover:text-gray-900"
                    >
                      <XMarkIcon
                        className="h-5 w-5"
                        onClick={() => setShowForm(false)}
                      />
                    </button>
                  </div>
                  <div className="py-4 px-6">
                    <div className="flex flex-col gap-6 text-sm">
                      <div className="flex flex-col gap-2 text-sm">
                        <label htmlFor="actividad">Actividad o servicio:</label>
                        <input
                          type="text"
                          id="actividad"
                          className="py-2.5 px-3 text-sm leading-normal border-gray-200 text-gray-400 rounded-md bg-gray-100"
                          value={catName}
                          disabled
                        />
                      </div>
                      <div className="flex flex-col gap-2 text-sm">
                        <label htmlFor="rango-temporal">Tematica:</label>
                        {idTheme ? (
                          <>
                            <input
                              type="text"
                              id="rango-temporal"
                              className="py-2.5 px-3 text-sm leading-normal border-gray-200 text-gray-400 rounded-md bg-gray-100"
                              value={selectedTheme.name}
                              disabled
                            />
                          </>
                        ) : (
                          <>
                            <select
                              className="text-sm py-2.5 px-3 leading-normal border-gray-300 text-gray-500 rounded-md"
                              value={selectedThemeOption}
                              onChange={handleThemeOption}
                            >
                              <option value={""}>Seleciona una tematica</option>
                              {themes.map(
                                (option) =>
                                  option.status === "ok" && (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  )
                              )}
                            </select>
                          </>
                        )}
                      </div>
                      <div className="flex flex-col gap-2 text-sm">
                        <label htmlFor="num-spinners">Nº de spinners:</label>
                        <select
                          id="num-spinners"
                          className="py-2.5 px-3 text-sm leading-normal border-gray-200 rounded-md bg-gray-100"
                          value={numSpinners}
                          onChange={handleNumSpinnersChange}
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                        </select>
                      </div>
                      {isLoading && (
                        <div>
                          <p className="block text-xs text-gray-500 text-center mb-4">
                            Estamos trabajando, esta operación puede llevar unos
                            segundos
                          </p>
                          <Spinner />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="py-4 px-6 flex flex-row-reverse gap-2">
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                      // Add onClick handler here to submit the form
                    >
                      Crear
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setShowForm(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Layout>
  );
}
