import { collection, doc, updateDoc } from "@firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "../firebase";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import {
  CheckIcon,
  XMarkIcon,
  PencilIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";

const SpinnerCard = ({ spinner, setSpinners, index, themes, data, setData, idCategory }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(null);
  const [selectedSpinnerId, setSelectedSpinnerId] = useState(null);

  const theme = themes.find((theme) => theme.id === spinner.idTheme);
  const themeName = theme ? theme.name : "";
  const saveSpinnersToDatabase = (updatedSpinners) => {
    const spinnersCollection = collection(db, "spinners");
    updatedSpinners.forEach((spinner) => {
      const spinnerDocRef = doc(spinnersCollection, spinner.id);
      updateDoc(spinnerDocRef, { text: spinner.text });
    });
  };

  const handleEdit = (spinnerId) => {
    try {
        setIsEditing(true);
        setSelectedSpinnerId(spinnerId);
        const selectedSpinner = data.find((spinner) => spinner.id === spinnerId);
        setEditText(selectedSpinner.text);
    } catch (error) {
        console.error("Error in handleEdit:", error);
    }
};

const handleNOK = async (spinnerId) => {
  try {
      const selectedSpinner = data.find((spinner) => spinner.id === spinnerId);

      if (selectedSpinner) {
          const updated = data.map((spinner) => {
              if (spinner.id === spinnerId) {
                  return {
                      ...spinner,
                      status: "nok",
                  };
              }
              return spinner;
          });
          setData(updated);
          const spinnerDocRef = doc(db, "spinners", spinnerId);
          await updateDoc(spinnerDocRef, { status: "nok" });
          console.log("Spinner status updated successfully.");
      }
  } catch (error) {
      console.error("Error in handleNOK:", error);
  }
};


const handleOK = async (spinnerId) => {
  try {
      const selectedSpinner = data.find((spinner) => spinner.id === spinnerId);
      console.log("selectedSpinner", selectedSpinner);
      if (selectedSpinner) {
          const updated = data.map((spinner) => {
              if (spinner.id === spinnerId) {
                  return {
                      ...spinner,
                      status: "ok",
                  };
              }
              return spinner;
          });
          setData(updated);
          const spinnerDocRef = doc(db, "spinners", spinnerId);
          await updateDoc(spinnerDocRef, { status: "ok" });
          console.log("Spinner status updated successfully.");
      }
  } catch (error) {
      console.error("Error in handleOK:", error);
  }
};

  const handleCancel = () => {
    setIsEditing(false);
    setSelectedSpinnerId(null);
    setEditText(null);
  };

  const handleSave = async () => {
    try {
        const updatedSpinners = data.map((spinner) => {
            if (spinner.id === selectedSpinnerId) {
                return {
                    ...spinner,
                    text: editText || spinner.text,
                };
            }
            return spinner;
        });
        setData(updatedSpinners);
        const spinnerDocRef = doc(db, "spinners", selectedSpinnerId);
        await updateDoc(spinnerDocRef, { edit_status: true });

        saveSpinnersToDatabase(updatedSpinners);
        setIsEditing(false);
        setSelectedSpinnerId(null);
        setEditText(null);
    } catch (error) {
        console.error("Error in handleSave:", error);
    }
};

  function createPromoText(ejemplo) {
    while (ejemplo.includes("{")) {
      const start = ejemplo.indexOf("{");
      const end = ejemplo.indexOf("}");
      const options = ejemplo.substring(start + 1, end).split("|");
      const selectedOption =
        options[Math.floor(Math.random() * options.length)];
      ejemplo =
        ejemplo.substring(0, start) +
        selectedOption +
        ejemplo.substring(end + 1);
    }
    return ejemplo;
  }
  const [promoText, setPromoText] = useState(spinner.promoText || createPromoText(spinner.text));

  const handleArrowPathIconClick = () => {
    const updatedPromoText = createPromoText(spinner.text);
    setPromoText(updatedPromoText);
  };


  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function formatDate(dateString) {
    if (!dateString) return ""; // Return empty string if dateString is not provided or invalid

    const date = dateString.toDate(); // Convert Firebase Timestamp to JavaScript Date object
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    const updatedPromoText = createPromoText(spinner.text);
    setPromoText(updatedPromoText);
  }, [spinner.text]);


  return (
    <div key={spinner.id} className="bg-white rounded-lg shadow-sm mb-4">
      <div className="p-6">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-9 h-9 rounded-lg bg-gray-100 -ml-1 text-gray-600 text-xs font-semibold">
            {index + 1}
          </div>
          <div className="ml-auto">
            <div className="ml-4 flex items-center gap-2">
              <button
                onClick={() => handleOK(spinner.id)}
                type="button"
                className={classNames(
                  spinner.status === "ok"
                    ? "text-success bg-success-100"
                    : "bg-gray-100",
                  "w-9 h-9 rounded-lg flex items-center justify-center"
                )}
              >
                <CheckIcon
                  className={classNames(
                    spinner.status === "ok" ? "text-success" : "text-gray-500",
                    "h-5 w-5"
                  )}
                />
              </button>
              <button
                onClick={() => handleNOK(spinner.id)}
                type="button"
                className={classNames(
                  spinner.status === "nok"
                    ? "text-danger bg-danger-100"
                    : "bg-gray-100",
                  "w-9 h-9 rounded-lg flex items-center justify-center"
                )}
              >
                <XMarkIcon
                  className={classNames(
                    spinner.status === "nok" ? "text-danger" : "text-gray-500",
                    "h-5 w-5"
                  )}
                />
              </button>
              <button
                type="button"
                className="w-9 h-9 rounded-lg bg-gray-100 flex items-center justify-center"
                onClick={() => handleEdit(spinner.id)}
              >
                <PencilIcon className="h-5 w-5 text-gray-500" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex mt-4">
          <div className="w-1/2 border-r border-gray-200 pr-4">
            <div className="flex items-center mb-4">
              <h3 className="font-medium text-gray-900">Spinner</h3>
              {spinner.status === "onhold" ? (
                <span className="rounded-full bg-gray-50 px-2.5 py-0.5 ml-2 text-[10px] uppercase text-white bg-gradient-to-r from-secondary to-tertiary">
                  Nuevo
                </span>
              ) : null}
            </div>
            <p className="text-gray-500 text-sm">{spinner.text}</p>
          </div>
          <div className="w-1/2 pl-4">
            <div className="flex items-center mb-4">
              <h3 className="font-medium text-gray-900">Ejemplo</h3>
              <button type="button" className="ml-2 text-gray-500">
                <ArrowPathIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                  onClick={() => handleArrowPathIconClick(spinner.id, spinner.text)}
                />
              </button>
            </div>
            <p className="text-gray-500 text-sm">{promoText}</p>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200 px-6 py-2.5">
        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <div
              className={classNames(
                spinner.status === "ok"
                  ? "text-success bg-success-100"
                  : spinner.status === "nok"
                  ? "text-danger bg-danger-100"
                  : "text-warning bg-warning-100",
                "rounded flex items-center flex-nowrap h-5 px-2 text-xs font-medium"
              )}
            >
              <span
                className={classNames(
                  spinner.status === "ok"
                    ? "bg-success-500"
                    : spinner.status === "nok"
                    ? "bg-danger-500"
                    : "bg-warning-500",
                  "rounded-full w-1.5 h-1.5 mr-1.5 inline-block"
                )}
              />
              {spinner.status === "ok"
                ? "Validado"
                : spinner.status === "nok"
                ? "Descartado"
                : "Pendiente"}
            </div>
            {spinner.edit_status && (
              <div className="rounded flex items-center flex-nowrap h-5 px-2 text-xs font-medium text-gray-500 bg-gray-100">
                <span className="rounded-full w-1.5 h-1.5 mr-1.5 inline-block bg-gray-400" />
                Editado
              </div>
            )}
          </div>
          <div className="flex items-center justify-end gap-2">
            <div className="text-xs border-r border-gray-200 pr-2">
              <span className="text-[10px] text-gray-500">Temática:</span>{" "}
              <Link
                className="border-b border-current hover:border-0"
                to={`/${idCategory}/themes/`}
              >
                {themeName}
              </Link>
            </div>
            <div className="text-xs">
              <span className="text-[10px] text-gray-500">Creado:</span>{" "}
              {formatDate(spinner.date_creation)}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        visible={isEditing && selectedSpinnerId === spinner.id}
        onHide={handleCancel}
        header={
          <div className="flex items-center">
            <PencilIcon className="h-5 w-5 text-gray-500 mr-2" />
            Editar spinner
          </div>
        }
        footer={
          <div className="flex justify-end">
            <Button
              label="Cancelar"
              className="p-button-outlined p-button-secondary"
              onClick={handleCancel}
            />
            <Button
              label="Guardar"
              className="p-button-secondary ml-2"
              onClick={handleSave}
            />
          </div>
        }
      >
        <form className="flex flex-col gap-6 text-sm">
          <div className="flex flex-col gap-2 text-sm">
            <InputTextarea
              value={editText || spinner.text}
              onChange={(e) => setEditText(e.target.value)}
              autoResize
              className="resize-none border border-gray-300 rounded px-2 py-1 w-full"
            />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default SpinnerCard;
