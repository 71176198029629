import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";

import AppRoutes from './App'
import { AuthProvider } from './AuthProvider'
import reportWebVitals from './reportWebVitals'
import './theme/tailwind-light/theme.scss'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

Sentry.init({
  dsn: "https://57415a084f9fbd3e44ce5d4d8198601d@o4505883589869568.ingest.sentry.io/4505883591901184",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/pre\.llm\.okabango\.com/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <AuthProvider>
    <AppRoutes />
  </AuthProvider>
  

)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
