import { useState, useRef, useContext } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { AuthContext } from "../AuthProvider";
import { useNavigate } from "react-router";
import { getAuth, signOut } from "@firebase/auth";

export const MenuProfile = ({ children }) => {
  const { user, logoutUserReactState, handleLogoutGen} = useContext(AuthContext);
  const navigate = useNavigate();
  const emailStorage = localStorage.getItem('email');

  const handleLogout = async () => {
    const auth = getAuth();

    try {
      handleLogoutGen();
      navigate("/login");

      logoutUserReactState();
    } catch (error) {
      console.log("Error al hacer logout:", error);
    }
  };

  //Menú profile
  const menuProfile = useRef(null);
  const [showMenuProfile, setShowMenuProfile] = useState(false);

  const itemsMenuProfile = [
    {
      label: "Cerrar sesión",
      command: () => {
        handleLogout();
        handleMenuProfile();
      },
    },
  ];

  const handleMenuProfile = () => {
    setShowMenuProfile(!showMenuProfile);
  };

  return (
    <>
      <Button
        className="p-button-tertiary p-0 h-8 w-8 flex items-center justify-center text-sm"
        rounded
        size="small"
        onClick={(event) => menuProfile.current.toggle(event)}
        aria-controls="popup_menu_left"
        aria-haspopup
        aria-label="Nombre de usuario"
      >
         {user?.reloadUserInfo?.email ? user.reloadUserInfo.email.substring(0, 2).toUpperCase() : emailStorage}
      </Button>
      <Menu
        model={itemsMenuProfile}
        popup
        ref={menuProfile}
        id="popup_menu_left"
        popupAlignment="left"
      />
    </>
  );
};
