import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import imgLogo from '../assets/images/logo-bee.svg'
import imgLogoFull from '../assets/images/logo-beedigital.png'
import { MenuProfile } from '../components/MenuProfile';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavigationMenu = ({ menu, menuOpen, setMenuOpen }) => {

  return (
    <>
      <div>
        <Transition.Root show={menuOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setMenuOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setMenuOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex grow flex-col overflow-y-auto'>
                    <div className="w-20 h-full bg-white px-4 py-8">
                      <div className="flex items-center justify-center">
                        <img
                          className="h-10 w-auto"
                          src={imgLogo}
                          alt="BeeDigital"
                        />
                      </div>
                      <nav className="mt-8">
                        <ul className="flex flex-1 flex-col gap-y-7">
                          <li>
                            <ul className="-mx-2 space-y-1">
                              {menu.map((item) => (
                                <li key={item.name}>
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      item.current
                                          ? 'flex items-center justify-center bg-secondary-50 text-secondary'
                                          : 'flex items-center justify-center hover:bg-gray-100',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium'
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current ? 'text-secondary' : '',
                                        'h-5 w-5 shrink-0'
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-20 lg:flex-col">
          <div className="flex grow flex-col overflow-y-auto bg-white px-5 py-8">
            <div className="flex items-center justify-center">
              <img
                className="h-10 w-auto"
                src={imgLogo}
                alt="BeeDigital"
              />
            </div>
            
            <nav className="mt-8">
              <ul className="flex flex-1 flex-col gap-y-7">
                {menu.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'flex items-center justify-center bg-secondary-50 text-secondary'
                          : 'flex items-center justify-center hover:bg-gray-100',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current ? 'text-secondary' : '',
                          'h-5 w-5 shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="mt-auto">
              {/* Profile dropdown */}
              <MenuProfile />
            </div>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setMenuOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          {/* <h1 className="text-lg font-medium text-gray-900">Dashboard</h1> */}
          <img
            className="h-full w-auto"
            src={imgLogoFull}
            alt="BeeDigital"
          />
        </div>
      </div>
    </>
  );
};

export default NavigationMenu;
