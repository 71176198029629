import React, { useState, useEffect, useContext, useRef} from 'react'
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, getMultiFactorResolver, linkWithCredential, sendSignInLinkToEmail} from 'firebase/auth'
import { doc, setDoc, serverTimestamp } from 'firebase/firestore'
import { useNavigate, Link } from 'react-router-dom'
import { auth, db } from '../firebase'
import {
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { AuthContext } from "../AuthProvider";
import {
  multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator,
  RecaptchaVerifier, signInWithCredential, sendEmailVerification
} from "firebase/auth";
import DoubleAuthenticationModal from '../components/DoubleAuthModal'
import PhoneAuthenticationModal from '../components/PhoneAuthModal'
import { useHistory } from 'react-router-dom';


import imgLogo from '../assets/images/logo-beedigital.png'



function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState("")
  const[user, setUser] = useState(null)
  const[verificationCode, setVerificationCode] = useState("")
  const[verificationId, setVerificationId] = useState("")
  const navigate = useNavigate()
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [visible, setVisible] = useState(false);
  const [code, setCode] = useState('');
  const [showModal, setShowModal] = useState(false);
  const {isVerificationSuccessful, setIsVerificationSuccessful } = useContext(AuthContext);
const [credMatch, setCredMatch] = useState(false);
  const [phoneNumberInput, setPhoneNumberInput] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false); // Move the state here
  const [showDoubleAuthModal, setShowDoubleAuthModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [userCredentialConst, setUserCredentialConst] = useState(null);
  const [isPhoneNumberUpdated, setIsPhoneNumberUpdated] = useState(false);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [userCredentialGlobal, setUserCredentialGlobal] = useState(null);

 

  const recaptchaVerifierRef = useRef(null);

useEffect(() => {
  // Initialize reCAPTCHA only once when the component mounts
  try {
    recaptchaVerifierRef.current = new RecaptchaVerifier('recaptcha-container', {
      "size": "invisible",
      "callback": function (response) {
        onSolvedRecaptcha();
      }
    }, auth);
  } catch (e) {
    console.error("Could not initialize reCAPTCHA: ", e);
  }
  
  // Optionally, clear reCAPTCHA when the component unmounts
  return () => {
    if (recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current.clear();
    }
  };
}, []); // Empty dependency array means this useEffect runs once when the component mounts

  const handleLogin = (providerName, event) => {
   
    console.log("executed");
    setIsVerificationSuccessful(false);
  
  
    console.log("executed 2");
  
    if (providerName === 'email') {
      event.preventDefault();
    }
  
    let user;
    let phoneNumber;
  
    signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        user = userCredential.user;
        console.log("user successfully signed in with first factor");
        localStorage.setItem('email', email);
        const usersCollectionRef = collection(db, 'users');
        return getDocs(query(usersCollectionRef, where('email', '==', email)));
      })
      .then(querySnapshot => {
        if (querySnapshot.size === 1) {
          querySnapshot.forEach(doc => {
            phoneNumber = doc.data().phoneNumber;
            setPhoneNumber(phoneNumber);
          });
  
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          return phoneAuthProvider.verifyPhoneNumber(phoneNumber, recaptchaVerifierRef.current);
        } else {
          console.log("User not found or multiple users found with the same email.");
        }
      })
      .then(verificationId => {
        const verificationCode = window.prompt('Please enter the verification code that was sent to your mobile device.');
        const phoneCred = PhoneAuthProvider.credential(verificationId, verificationCode);
  
        if (user.providerData.some(provider => provider.providerId === "phone")) {
          console.log("User already has a phone linked. Proceeding to verify anyway.");
          return signInWithCredential(auth, phoneCred);
        }
        console.log("Linking phone credential to the user.");
        return linkWithCredential(user, phoneCred);
      })
      .then(() => {
        console.log("User successfully signed in with second factor");
        setIsVerificationSuccessful(true);
        navigate("/");
      })
      .catch((error) => {
        console.log("Error:", error);
        if (error.code === 'auth/invalid-verification-code') {
          console.log('The provided verification code is invalid.');
          setIsVerificationSuccessful(false);
        }
      });
  };
  
  
  
  



  //ok probably when I close the modal something is making the phoneNumber null, check that out








  const onSolvedRecaptcha = () => {
    // This function will be called when the reCAPTCHA challenge is solved.
    // You can add your custom logic here.
    console.log("reCAPTCHA solved");
    // Any additional actions you want to perform can go here.
  };



  const handleVerificationCode = (code) => {
    setVerificationCode(code); // Store the verification code in the state
    // You can now use verificationCode in your LoginPage component
    console.log('Verification Code in LoginPage:', code);
    // You can perform any other actions with the verification code as needed


  };





  

  return (
    <>
      {/*
          This example requires updating your template:

          ```
          <html class="h-full bg-gray-50">
          <body class="h-full">
          ```
        */}
        <div id="test">
      <div className='flex min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-100'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md mb-8 text-center'>
          <img
            className='mx-auto mb-6'
            src={imgLogo}
            alt='BeeDigital'
          />
          <h2 className='text-2xl lg:text-3xl xl:text-4xl font-semibold'>
            Revoluciona la
            <span className="block text-secondary">creación de contenidos</span>
          </h2>
          <p className="text-sm mt-2">
            IA para la creación de contenido dinámico
          </p>
        </div>
        <div id="container">
        <div id="recaptcha-container"></div>
          <div className='sm:mx-auto sm:w-full sm:max-w-[480px]'>
            <div className='bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12'>
              <form className='space-y-6' onSubmit={event => handleLogin('email', event)}>
                <div>
                  <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>
                    Usuario
                  </label>
                  <div className='mt-2'>
                    <input
                      id='email'
                      name='email'
                      type='email'
                      autoComplete='email'
                      required
                      value={email} onChange={e => setEmail(e.target.value)}
                      className='block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6'
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor='password' className='block text-sm font-medium leading-6 text-gray-900'>
                    Contraseña
                  </label>
                  <div className='mt-2'>
                    <input
                      id='password'
                      name='password'
                      type='password'
                      autoComplete='current-password'
                      required
                      value={password} onChange={e => setPassword(e.target.value)}
                      className='block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6'
                    />
                  </div>
                </div>

                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    <input
                      id='remember-me'
                      name='remember-me'
                      type='checkbox'
                      className='h-4 w-4 rounded border-gray-300 text-secondary focus:ring-secondary'
                    />
                    <label htmlFor='remember-me' className='ml-3 block text-sm leading-6 text-gray-900'>
                      Recordar contraseña
                    </label>
                  </div>
                  <div className='text-sm leading-6'>
                    <Link to='/reset-password' className='text-secondary hover:text-secondary-100'>
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <div id="sign-in">
                  <button id="sign-in-button"
                    type='submit'
                    className='flex w-full justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black'
                    label="Open Double Authentication Modal"
                    //onClick={handleClickLogin}
                  >
                      Entrar
                    </button>
                
                   
                  </div>
                  {error && <p>{error}</p>}
                </form>

            </div>

          </div>
        </div>
      </div>

    
 
    </div>
    </>
  )
}

export { LoginPage }; // Export the function
export default LoginPage;
