/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { Layout } from "../layout/Layout";
import {
  FolderIcon,
  Squares2X2Icon,
  RectangleStackIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";

import { v4 as uuidv4, v4 } from "uuid";

import { SparklesIcon } from "@heroicons/react/24/solid";
import {
  collection,
  // updateDoc,
  // doc,
  query,
  where,
  // writeBatch,
  getDocs,
  onSnapshot,
  orderBy,
  addDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import { useParams, Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import FilterCalendar from "../components/FilterCalendar";
import { updateDatabaseCounts } from "../services/DBService";
import { ThemeCard } from "../components/ThemeCard";
import Sidebar from "../layout/Sidebar";
import CreateFrom from "../components/CreateFrom";

export default function ThemePage() {
  // Constants

  const [themes, setThemes] = useState([]);
  const [filteredThemes, setFilteredThemes] = useState([]);
  // const [isEditing, setIsEditing] = useState(false);
  // const [editText, setEditText] = useState(null);
  // const [selectedThemeId, setSelectedThemeId] = useState(null);
  const { idCategory } = useParams();
  const [showForm, setShowForm] = useState(false);
  const menuTheme = useRef(null); //Menu desplegable de crear temática manual
  const [showMenuTheme, setShowMenuTheme] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [startDateTheme, setStartDateTheme] = useState(null);
  const [endDateTheme, setEndDateTheme] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  const navigation = [
    {
      name: "Overview",
      href: "/overview",
      icon: Squares2X2Icon,
      current: false,
    },
    { name: "Temáticas (eventos)", href: "/themes", icon: FolderIcon, current: true },
    {
      name: "Spinners (plantillas)",
      href: "/overview/spinners",
      icon: RectangleStackIcon,
      current: false,
    },
  ];

  //Menú desplegable de crear temática manual
  const itemsMenuTheme = [
    {
      label: "Crear temática manual",
      command: () => {
        handleMenuTheme();
      },
    },
  ];

  const handleMenuTheme = () => {
    setShowMenuTheme(!showMenuTheme);
  };

  const options = [
    { id: 1, name: "Enero" },
    { id: 2, name: "Febrero" },
    { id: 3, name: "Marzo" },
    { id: 4, name: "Abril" },
    { id: 5, name: "Mayo" },
    { id: 6, name: "Junio" },
    { id: 7, name: "Julio" },
    { id: 8, name: "Agosto" },
    { id: 9, name: "Septiembre" },
    { id: 10, name: "Octubre" },
    { id: 11, name: "Noviembre" },
    { id: 12, name: "Diciembre" },
    { id: 13, name: "Primavera" },
    { id: 14, name: "Verano" },
    { id: 15, name: "Otoño" },
    { id: 16, name: "Invierno" },
  ];

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };



  const fetchThemes = async (categoryId, startDate, endDate) => {
    try {
      const themesCollection = collection(db, "themes");
      onSnapshot(
        query(
          themesCollection,
          where("idCategory", "==", parseInt(categoryId)),
          orderBy("date_creation", "desc")
        ),
        async (snapshot) => {
          const themesData = snapshot.docs.map((doc) => doc.data());

          let filteredThemes = themesData;

          if (startDateTheme && endDateTheme) {
            filteredThemes = filterThemesByDate(startDate, endDate, themesData);
          }

          setThemes(themesData);
          setFilteredThemes(filteredThemes);
          console.log("TENEMOS CAMBIOS!");
        }
      );
    } catch (error) {
      console.error("Error fetching themes:", error);
    }
  };

  useEffect(() => {
    fetchThemes(Number(idCategory), startDateTheme, endDateTheme);
  }, [idCategory, startDateTheme, endDateTheme]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            // Execute updateDatabaseCounts
            await updateDatabaseCounts();
        } catch (error) {
            console.error("Error in fetchData:", error);
        }
    };

    fetchData();
}, []);

  //Tabs

  const tabs = [
    {
      name: "Generados",
      href: "#",
      count: filteredThemes.length,
      current: true,
      value: "gen",
    },
    {
      name: "Validados",
      href: "#",
      count: filteredThemes.filter((theme) => theme.status === "ok").length,
      current: false,
      value: "ok",
    },
    {
      name: "Editados",
      href: "#",
      count: filteredThemes.filter((theme) => theme.edit_status === true)
        .length,
      current: false,
      value: "edit",
    },
    {
      name: "Descartados",
      href: "#",
      count: filteredThemes.filter((theme) => theme.status === "nok").length,
      current: false,
      value: "nok",
    },
    {
      name: "Pendientes",
      href: "#",
      count: filteredThemes.filter((theme) => theme.status === "onhold").length,
      current: false,
      value: "onhold",
    },
  ];

  const [activeTab, setActiveTab] = useState(
    tabs.find((tab) => tab.current).name
  );
  const [tabValue, setTabValue] = useState(
    tabs.find((tab) => tab.current).value
  );

  function handleTabClick(tab) {
    setActiveTab(tab);
  }

  useEffect(() => {
    setTabValue(tabs.find((tab) => tab.name === activeTab).value);
  }, [activeTab]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }


  const generateCards2 = () => {
    let newFilteredThemes = []; // Declare newFilteredThemes variable

    if (activeTab === "Generados") {
      newFilteredThemes = filteredThemes;
    } else if (activeTab === "Validados") {
      newFilteredThemes = themes.filter((theme) => theme.status === "ok");
    } else if (activeTab === "Descartados") {
      newFilteredThemes = themes.filter((theme) => theme.status === "nok");
    } else if (activeTab === "Editados") {
      newFilteredThemes = themes.filter((theme) => theme.edit_status);
    } else if (activeTab === "Pendientes") {
      newFilteredThemes = themes.filter((theme) => theme.status === "onhold");
    }

    // setFilteredThemes(newFilteredThemes);

    return {
      newFilteredThemes,
    };
  };

  //Retrieve from generateCards2

  // const { newFilteredThemes } = generateCards2();

  useEffect(() => {
    generateCards2();
    // setFilteredThemes(newFilteredThemes);
  }, [activeTab, themes]);

  //Show form

  const handleButtonClick = () => {
    setShowForm(true);
  };

  //Stats

  const formattedThemes = filteredThemes.map((theme) => ({
    id: theme.id,
    idTheme: theme.idTheme,
    config: theme.config,
    idCategory: theme.idCategory,
    status: theme.status,
    date_creation: theme.date_creation,
    prompt_tokens: theme.config?.prompt_tokens || 0,
    total_tokens: theme.config?.total_tokens || 0,
    cost: theme.cost || 0,
  }));

  const stats = [
    {
      id: 1,
      name: "Completion tokens",
      stat: formattedThemes
        .reduce((sum, theme) => sum + (theme.config?.respond_tokens || 0), 0)
        .toFixed(0),
    },
    {
      id: 2,
      name: "Prompt tokens",
      stat: formattedThemes
        .reduce((sum, theme) => sum + (theme.config?.prompt_tokens || 0), 0)
        .toFixed(0),
    },
    {
      id: 3,
      name: "Total tokens",
      stat: formattedThemes
        .reduce((sum, theme) => sum + (theme.config?.total_tokens || 0), 0)
        .toFixed(0),
    },
    {
      id: 4,
      name: "Coste total",
      stat: formattedThemes
        .reduce(
          (acc, theme) => acc + parseFloat(theme.config.total_cost || 0),
          0
        )
        .toFixed(3).replace('.', ','),
    },
  ];

  async function handleFilterThemeByDate(startDate, endDate, toFilterData) {
    const filteredData = themes.filter((theme) => {
      const themeDate = new Date(theme.date_creation.seconds * 1000);

      setStartDateTheme(startDate);
      setEndDateTheme(endDate);
      return themeDate >= startDate && themeDate <= endDate;
    });

    return filteredData;
  }

  function filterThemesByDate(startDate, endDate, themesData) {
    return themesData.filter((theme) => {
      const themeDate = new Date(theme.date_creation.seconds * 1000);
      return themeDate >= startDate && themeDate <= endDate;
    });
  }

  // async function handleFilterThemeByDate(startDate, endDate, toFilterData) {
  //   try {
  //     const filteredData = [];

  //     await Promise.all(
  //       toFilterData.map(async (theme) => {
  //         const themesCollection = collection(db, "themes");

  //         const themesQuery = query(
  //           themesCollection,
  //           where("idCategory", "==", parseInt(idCategory)),
  //           where("date_creation", ">=", startDate),
  //           where("date_creation", "<=", endDate)
  //         );

  //         const themesSnapshot = await getDocs(themesQuery);
  //         const filteredThemes = themesSnapshot.docs.map((doc) => doc.data());

  //         // Apply additional filters or transformations as needed

  //         if (filteredThemes.length > 0) {
  //           filteredData.push(filteredThemes);

  //         }
  //       })
  //     );

  //     return filteredData;
  //   } catch (error) {
  //     console.error("Error filtering themes:", error);
  //     throw error;
  //   }
  // }

  //*Manual theme
  const [manualTheme, setManualTheme] = useState({
    themeName: "",
    description: "",
    timeRange: "",
  });

  const [isLoadingManualTheme, setIsLoadingManualTheme] = useState(false);

  //On change manual theme form
  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setManualTheme({
      ...manualTheme,
      [e.target.name]: e.target.value,
    });
  };

  //On submit manual theme form
  const handleSubmit = async () => {
    setIsLoadingManualTheme(true);
    if (
      manualTheme.themeName === "" ||
      manualTheme.description === "" ||
      manualTheme.timeRange === ""
    ) {
      alert("Debes rellenar todos los campos");
      setIsLoadingManualTheme(false);
      return;
    }

    try {
      setIsLoadingManualTheme(true);
      const newTheme = {
        config: {
          cost: 0,
          group: 1,
          model: "no model",
          prompt_tokens: 0,
          respond_tokens: 0,
          total_cost: 0,
          total_tokens: 0,
        },
        id: uuidv4(),
        date_creation: new Date(),
        edit_status: false,
        idCategory: Number(idCategory),
        name: manualTheme.themeName,
        spinners: {
          cost: 0,
          edited: 0,
          gen: 0,
          nok: 0,
          ok: 0,
          onhold: 0,
        },
        status: "onhold",
        text: manualTheme.description,
        time_range: manualTheme.timeRange,
      };

      const docId = newTheme.id;
      await setDoc(doc(db, "themes", docId), newTheme);

      console.log("Theme created");
      setIsLoadingManualTheme(false);
    } catch (error) {
      console.log("crear tematica manual", error);
      setIsLoadingManualTheme(false);
    }

    handleMenuTheme();
  };

  const [catName, setCatName] = useState(null);

  const fetchCategoryName = async (idCategory) => {
    try {
      const categoriesColRef = collection(db, "categories");
      const querySnapshot = await getDocs(
        query(categoriesColRef, where("id", "==", parseInt(idCategory)))
      );

      if (querySnapshot.empty) {
        // Handle case where the category with the given id does not exist
        console.log("Category not found");
        return;
      }

      querySnapshot.forEach((doc) => {
        const categoryName = doc.data().name;
        setCatName(categoryName);
      });
    } catch (error) {
      // Handle any errors that occur during the fetching process
      console.error("Error fetching category:", error);
    }
  };

  fetchCategoryName(idCategory);

  return (
    <Layout>
      {/* Sidebar */}
      <Sidebar navigation={navigation} />
      {/* End Sidebar */}
      <div className="pl-20 xl:pl-64 min-h-[--main-height]">
        <div className="mx-auto xl:max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex items-center">
            <h1 className="text-3xl flex items-center">Temáticas</h1>
            <div className="ml-auto flex p-buttonset">
              <button
                onClick={() => handleButtonClick()}
                type="button"
                className="inline-flex items-center border-r border-white rounded-l-md px-3 py-2 text-sm text-white bg-gradient-to-r from-secondary to-tertiary"
              >
                <SparklesIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                Generar temáticas
              </button>
              <Button
                className="p-button-tertiary"
                icon="pi pi-chevron-down"
                size="small"
                onClick={(event) => menuTheme.current.toggle(event)}
                aria-controls="popup_menu_right"
                aria-haspopup
              />
              <Menu
                model={itemsMenuTheme}
                popup
                ref={menuTheme}
                id="popup_menu_right"
                popupAlignment="right"
              />
            </div>
          </div>
          <h2 className="block xl:hidden text-xl font-semibold truncate">
            {catName}
          </h2>
          {/* Filtros */}
          <div className="flex flex-col gap-2 md:flex-row mt-6">
            <div className="flex flex-col gap-2 sm:flex-row">
              <FilterCalendar
                initialData={themes}
                searchQuery={searchQuery}
                handlerForFilterByDate={handleFilterThemeByDate}
                setFilterInitialData={setFilteredThemes}
                setSearchQuery={setSearchQuery}
                showClearFilterButton
              />
            </div>
          </div>
          {/* End Filtros */}
          {/* KPIs */}
          <div className="mt-6">
            <dl className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
              {stats.map((item) => (
                <div
                  key={item.id}
                  className="relative overflow-hidden rounded-lg bg-white p-6 shadow-sm text-left"
                >
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd>
                    <p className="text-3xl font-semibold text-gray-900">
                      {item.stat}
                      {item.name === "Coste total" ? (
                        <span className="text-xl">€</span>
                      ) : null}
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          {/* End KPIs */}
          {/* Tabs */}
          <div className="mt-6 sm:mt-4">
            {/* Tabs mobile */}
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={activeTab}
                onChange={(e) => handleTabClick(e.target.value)}
              >
                {tabs.map((tab) => (
                  <option key={tab.name} value={tab.name}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            {/* Tabs desktop */}
            <div className="hidden sm:block">
              <div className="border-y border-gray-200">
                <nav
                  className="-mb-px flex space-x-8 overflow-x-auto overflow-y-hidden"
                  aria-label="Tabs"
                >
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      className={classNames(
                        activeTab === tab.name
                          ? "border-primary text-primary"
                          : "border-transparent text-gray-500 hover:text-gray-700",
                        "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                      )}
                      onClick={() => handleTabClick(tab.name)}
                      aria-current={activeTab === tab.name ? "page" : undefined}
                    >
                      {tab.name}
                      {/* {tab.count ? ( */}
                      <span
                        className={classNames(
                          activeTab === tab.name
                            ? "bg-primary-500 text-primary"
                            : "bg-white text-gray-900",
                          "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block"
                        )}
                      >
                        {tab.count}
                      </span>
                      {/* ) : null} */}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          {/* End Tabs */}

          {/* Listado de temáticas */}
          {themes.length > 0 ? (
            <div className="mt-6">
              {tabValue === "gen" &&
                filteredThemes.map((theme, index) => (
                  <ThemeCard theme={theme} index={index} themes={themes} />
                ))}

              {tabValue !== "gen" &&
                tabValue !== "edit" &&
                filteredThemes.map(
                  (theme, index) =>
                    theme.status === tabValue && (
                      <ThemeCard themes={themes} theme={theme} index={index} />
                    )
                )}

              {tabValue === "edit" &&
                filteredThemes.map(
                  (theme, index) =>
                    theme.edit_status && (
                      <ThemeCard themes={themes} theme={theme} index={index} />
                    )
                )}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center mt-10">
              <div className="text-4xl">
                <code>(&gt;_&lt;)</code>
              </div>
              <p className="block text-sm mt-6">
                No hay temáticas para mostrar
              </p>
              <p className="block text-gray-400 text-sm">
                Crea tu primera temática
              </p>
              <button
                onClick={() => handleButtonClick()}
                type="button"
                className="mt-6 inline-flex items-center rounded-md px-3 py-2 text-sm text-white bg-gradient-to-r from-secondary to-tertiary"
              >
                <SparklesIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                Generar temáticas
              </button>
            </div>
          )}
        </div>

        {/* Modal Crear temática */}
        {showForm && (
          <CreateFrom
            catName={catName}
            idCategory={idCategory}
            setShowForm={setShowForm}
          />
        )}

        {/* Modal crear temática manual */}
        <Dialog
          visible={showMenuTheme}
          onHide={handleMenuTheme}
          header={
            <div className="flex items-center">
              <PlusCircleIcon className="h-5 w-5 text-gray-500 mr-2" />
              Crear temática manual
            </div>
          }
          footer={
            <div className="flex justify-end">
              <Button
                label="Cancelar"
                className="p-button-outlined p-button-secondary"
                onClick={handleMenuTheme}
              />
              <Button
                label="Guardar"
                type="submit"
                className="p-button-secondary ml-2"
                onClick={handleSubmit}
              />
              {isLoadingManualTheme && <p>Loading...</p>}
            </div>
          }
        >
          <form className="flex flex-col gap-6 text-sm">
            <div className="flex flex-col gap-2 text-sm">
              <label htmlFor="themeName">Temática:</label>
              <InputTextarea
                inputid="themeName"
                name="themeName"
                autoResize
                className="resize-none border border-gray-300 rounded px-2 py-1 w-full"
                placeholder="Escribe el nombre de la temática"
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-2 text-sm">
              <label htmlFor="rango-temporal">Rango temporal</label>
              <select
                className="text-sm py-2.5 px-3 leading-normal border-gray-300 text-gray-500 rounded-md"
                value={manualTheme.timeRange}
                name="timeRange"
                onChange={handleChange}
              >
                <option value={""}>Seleciona una opcion</option>
                {options.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-2 text-sm">
              <label htmlFor="description">Descripción de la Temática:</label>
              <InputTextarea
                inputid="description"
                name="description"
                autoResize
                className="resize-none border border-gray-300 rounded px-2 py-1 w-full"
                placeholder="Escribe una descripción de la temática"
                onChange={handleChange}
              />
            </div>
          </form>
        </Dialog>
      </div>
    </Layout>
  );
}
