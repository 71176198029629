import { useState } from 'react';
import { HomeIcon } from '@heroicons/react/24/outline';
import NavigationMenu from './NavigationMenu';

export const Layout = ({ children }) => {
  
  const [menuOpen, setMenuOpen] = useState(false);

  const menu = [
    { name: '', href: '/home', icon: HomeIcon, current: true },
  ];

  return (
    <>
      <NavigationMenu
        menu={menu}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
    
      <main className='bg-gray-100 lg:pl-20 min-h-[--main-height]'>
        {children}
      </main> 
       
    </>
  );
};
