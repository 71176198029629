import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useHref } from "react-router-dom";
import { CalendarIcon } from "@heroicons/react/24/outline";

export default function FilterCalendar({
  setFilterInitialData,
  initialData,
  searchQuery,
  setSearchQuery,
  handlerForFilterByDate,
  showClearFilterButton,
}) {
  const [date, setDate] = useState(null);
  const submitButtonRef = useHref(null);
  const [isDateFilterActive, setIsDateFilterActive] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  function handleChangeCalendar(e) {
    setDate(e.value);
  }

  async function handleSubmitFilter(e) {
    e.preventDefault();
    let filteredData = initialData;

    try {
        if (searchQuery) {
            filteredData = initialData.filter((category) =>
                category.name.toLowerCase().includes(searchQuery)
            );
        }

        if (date?.length < 2 || !date) {
            setIsDateFilterActive(false);
            setFilterInitialData(filteredData);
            return;
        } else {
            const sortedDates = date
                .map((dateStr) => new Date(dateStr))
                .sort((a, b) => a.getTime() - b.getTime());

            const startDate = sortedDates[0];
            const endDate = sortedDates[1];

            endDate.setHours(23, 59, 59, 999);

            // Set the manually selected dates
            setDate([startDate, endDate]);

            // Now you can use the startDate and endDate as needed, for example, to filter the categories.
            filteredData = await handlerForFilterByDate(
                startDate,
                endDate,
                filteredData
            );
        }

        setFilterInitialData([...filteredData]);

    } catch (error) {
        console.error("Error occurred while filtering:", error);
        // You can also display an error message to the user if required.
    }
}

  useEffect(() => {
    function handleKeyDown(event) {
      if (
        event.key === "Enter" &&
        submitButtonRef.current &&
        submitButtonRef.current.offsetParent !== null
      ) {
        handleSubmitFilter(event);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [submitButtonRef]);

  return (
    <div className="flex flex-col gap-2 sm:flex-row">
      <div className="relative">
        <Calendar
          value={date}
          onChange={handleChangeCalendar}
          selectionMode="range"
          readOnlyInput
          showButtonBar
          dateFormat="dd/mm/yy"
        />
        <div className="flex items-center justify-center absolute inset-y-0 right-0 w-10 pointer-events-none">
          <CalendarIcon className="h-5 w-5" aria-hidden="true" />
        </div>
      </div>
      <Button type="submit" label="Filtrar" onClick={handleSubmitFilter} />

      {showClearFilterButton && (
        <Button
          type="button"
          severity="secondary"
          onClick={() => {
            setDate(null);
            setFilterInitialData(initialData);
            setSearchQuery("");
          }}
          outlined 
        >Borrar <span className="hidden lg:block ml-1.5">filtros</span></Button>
      )}
    </div>
  );
}
