import { useState } from "react";
import API from "../utils/api";

import { SparklesIcon } from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Spinner from "./Spinner";

const options = [
  { id: 1, name: "Enero" },
  { id: 2, name: "Febrero" },
  { id: 3, name: "Marzo" },
  { id: 4, name: "Abril" },
  { id: 5, name: "Mayo" },
  { id: 6, name: "Junio" },
  { id: 7, name: "Julio" },
  { id: 8, name: "Agosto" },
  { id: 9, name: "Septiembre" },
  { id: 10, name: "Octubre" },
  { id: 11, name: "Noviembre" },
  { id: 12, name: "Diciembre" },
  { id: 13, name: "Primavera" },
  { id: 14, name: "Verano" },
  { id: 15, name: "Otoño" },
  { id: 16, name: "Invierno" },
];

export default function CreateFrom({ idCategory, setShowForm, catName }) {
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoadingCreateTheme, setIsLoadingCreateTheme] = useState(false);
  const [manualTheme, setManualTheme] = useState({
    name: "",
    description: "",
  });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const postTheme = async (timeRange) => {
    const payload = {
      idCategory: Number(idCategory),
      stringRangoTemporal: timeRange,
    };
    setIsLoadingCreateTheme(true);

    try {
      setIsLoadingCreateTheme(true);
      const response = await fetch(
        `${API.ROOT}${API.ADD_THEME}/${payload.idCategory}/${payload.stringRangoTemporal}`
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("POST request successful:", responseData);
        setIsLoadingCreateTheme(false);
      } else {
        console.log("POST request failed:", response.status);
        setIsLoadingCreateTheme(false);
      }
    } catch (error) {
      console.error("Error making POST request:", error);
      setIsLoadingCreateTheme(false);
    }
  };

  async function handleSubmitCreateThemes(e) {
    e.preventDefault();
    if (!selectedOption) return;
    await postTheme(selectedOption);
    setShowForm(false);
  }

  return (
    <form
      className="fixed z-10 inset-0 overflow-y-auto "
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      onSubmit={handleSubmitCreateThemes}
    >
      <div className="flex relative  items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
        >
          <div className="py-5 px-6 flex border border-gray-100">
            <div className="flex items-center">
              <SparklesIcon className="h-4 w-4 text-secondary mr-2" />
              <h5 className="font-medium" id="modal-title">
                Crear temática
              </h5>
            </div>
            <button
              type="button"
              className="h-8 w-8 flex items-center justify-center text-gray-500 ml-auto rounded-full overflow-hidden hover:bg-gray-100 hover:text-gray-900"
            >
              <XMarkIcon
                className="h-5 w-5"
                onClick={() => {
                  setShowForm(false);
                }}
              />
            </button>
          </div>
          <div className="py-4 px-6">
            <div className="flex flex-col gap-6 text-sm">
              <div className="flex flex-col gap-2 text-sm">
                <label htmlFor="actividad">Actividad o servicio</label>
                <input
                  type="text"
                  id="actividad"
                  className="py-2.5 px-3 text-sm leading-normal border-gray-200 text-gray-400 rounded-md bg-gray-100"
                  value={catName}
                  disabled
                />
              </div>
              <div className="flex flex-col gap-2 text-sm">
                <label htmlFor="rango-temporal">Rango temporal</label>
                <select
                  className="text-sm py-2.5 px-3 leading-normal border-gray-300 text-gray-500 rounded-md"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value={""}>Seleciona una opcion</option>
                  {options.map((option) => (
                    <option key={option.id} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-2 text-sm">
                <label htmlFor="num-tematicas">Nº de temáticas:</label>
                <input
                  type="text"
                  id="num-spinners"
                  className="tpy-2.5 px-3 text-sm leading-normal border-gray-200 text-gray-400 rounded-md bg-gray-100"
                  value={3}
                  disabled
                  // Add the appropriate state and onChange handler here
                />
              </div>
              {isLoadingCreateTheme && <div><p className="block text-xs text-gray-500 text-center mb-4">Estamos trabajando, esta operación puede llevar unos segundos</p><Spinner /></div>}
            </div>
          </div>
          <div className="py-4 px-6 flex flex-row-reverse gap-2">
            <button
              type="submit"
              className="inline-flex items-center rounded-md px-3 py-2 text-sm text-white bg-gradient-to-r from-secondary to-tertiary"
              disabled={!selectedOption}
            >
              <SparklesIcon
                className="-ml-0.5 mr-1.5 h-5 w-5"
                aria-hidden="true"
              />
              Generar
            </button>
            <button
              type="button"
              className="inline-flex rounded-md border border-black px-4 py-2 bg-white text-base font-medium text-gray-900 hover:bg-black hover:text-white text-sm"
              onClick={() => {
                setShowForm(false);
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
